import { isDevMode } from '@angular/core';
import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import * as fromAuth from './auth/auth.reducer';
import * as fromEmployees from './employees/employees.reducer';
import * as fromMap from './map/map.reducer';
import * as fromBoard from './board/board.reducer';
import * as fromChat from './chat/chat.reducer';
import * as fromNotifications from './notifications/notifications.reducer';
import * as fromFleet from './fleet/fleet.reducer';
import * as fromContractors from './contractors/contractors.reducer';
import * as fromShipments from './shipments/shipments.reducer';
export interface State {
	auth: fromAuth.AuthState,
	employees: fromEmployees.EmployeesState
	map: fromMap.MapState
	tasks: fromBoard.BoardState,
	chat: fromChat.ChatState,
	notifications: fromNotifications.NotificationsState,
	fleet: fromFleet.FleetState,
	contractors: fromContractors.ContractorsState,
	shipments: fromShipments.ShipmentsState
}

export const reducers: ActionReducerMap<State> = {
	auth: fromAuth.authReducer,
	employees: fromEmployees.employeesReducer,
	map: fromMap.mapReducer,
	tasks: fromBoard.boardReducer,
	chat: fromChat.chatReducer,
	notifications: fromNotifications.notificationsReducer,
	fleet: fromFleet.fleetReducer,
	contractors: fromContractors.contractorsReducer,
	shipments: fromShipments.shipmentsReducer
};

export function signOutMetaReducer(reducer: ActionReducer<any>): ActionReducer<any> {
	return (state: any, action: any): State => {
		if (action.type === '[Auth] Sign out') {
			state = undefined;
		}
		return reducer(state, action);
	};
}

export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
	return (state, action) => {
	  const result = reducer(state, action);
	
	  console.groupCollapsed(action.type);
	  console.log(`Prev state:`, state);
	  console.log(`Action:`, action);
	  console.log(`Next state:`, result);
	  console.groupEnd();
  
	  return result;
	};
  }


export const metaReducers: MetaReducer<State>[] = isDevMode() ? [signOutMetaReducer, logger] : [signOutMetaReducer];
