import { createAction, props } from '@ngrx/store';
import { Address } from 'src/app/customer/shared/interfaces/address.interface';
import { EnrichedContractor } from 'src/app/customer/contractors/types.interface';

export const setContractors = createAction(
	'[Contractors] Set contractors',
	props<{ contractors: EnrichedContractor[] }>()
)

export const addNewContractorAddress = createAction(
	'[Contractors] Add new contractor address',
	props<{ contractorId: string, address: Address }>()
)

export const updateContractorAddress = createAction(
	'[Contractors] Update contractor address',
	props<{ contractorId: string, address: Address }>()
)

export const setContractorMainAddress = createAction(
	'[Contractors] Set contractor main address',
	props<{ contractorId: string, addressId: string }>()
)

export const toggleActiveContractorStatus = createAction(
	'[Contractors] Toggle contractor activity status',
	props<{ contractorId: string }>()
)

export const toggleActiveContractorAddressStatus = createAction(
	'[Contractors] Toggle contractor address activity status',
	props<{ contractorId: string, addressId: string }>()
)