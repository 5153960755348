import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, tap } from 'rxjs';
import { ApiRoutes } from 'src/app/shared/configuration/api-routes';
import { LoginReq } from '../models/login-req.model';
import { LoginResp } from '../models/login-resp.model';
import jwt_decode from 'jwt-decode';
import { Role } from '../enums/role.enum';
import { UserData } from '../interfaces/user-data.interface';
import { SignUpPayload } from '../interfaces/sign-up-payload.interface';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	private readonly tokenKey = 'token';
	private readonly refreshTokenKey = 'refreshToken';

	constructor(
		private http: HttpClient,
		private cookie: CookieService,
	) { }

	login = (payload: LoginReq): Observable<LoginResp> =>
		this.http.post<LoginResp>(ApiRoutes.auth.login, payload);

	loginWithNewPassword = (payload: LoginReq): Observable<LoginResp> =>
		this.http.post<LoginResp>(ApiRoutes.auth.loginWithNewPassword, payload);

	signUp = (payload: SignUpPayload): Observable<any> =>
		this.http.post<any>(ApiRoutes.auth.signUp, payload);

	logout = (): void => {
		this.cookie.delete(this.tokenKey);
		this.cookie.delete(this.refreshTokenKey);
		window.location.href = '/auth';
	}

	refreshToken = (): Observable<any> => {
		const { refreshToken } = this.getUserSessionCookies();
		return this.http.post<LoginResp>(ApiRoutes.auth.refreshToken, { refreshToken }).pipe(
			tap(({access_token, refresh_token}) => {
				this.setUserSessionCookies(access_token, refresh_token)
			})
		)
	}

	resetPassword = (email: string): Observable<any> =>
		this.http.post<any>(ApiRoutes.auth.resetPassword, { email })

	setUserSessionCookies = (token: string, refreshToken: string): void => {
		this.cookie.set(this.tokenKey, token);
		this.cookie.set(this.refreshTokenKey, refreshToken);
	}

	getUserSessionCookies = (): { token: string, refreshToken: string } => {
		return {
			token: this.cookie.get(this.tokenKey),
			refreshToken: this.cookie.get(this.refreshTokenKey)
		}
	}

	isLoggedIn = (): boolean => !!this.cookie.get(this.tokenKey);

	isTruckDriveAdmin = (): boolean => {
		const { token } = this.getUserSessionCookies();
		const { realm_access: { roles } }: { realm_access: { roles: string[] } } = this.decodeJWT(token);
		return roles?.includes(Role.truckdriveAdmin);
	}

	isSalesmanAdmin = (): boolean => {
		const { token } = this.getUserSessionCookies();
		const { realm_access: { roles } }: { realm_access: { roles: string[] } } = this.decodeJWT(token);
		return roles?.includes(Role.salesmanAdmin);
	}

	isCustomer = (): boolean => {
		const { token } = this.getUserSessionCookies();
		const { realm_access: { roles } }: { realm_access: { roles: string[] } } = this.decodeJWT(token);
		return roles?.includes(Role.customerAdmin) || roles?.includes(Role.customerUser) || roles?.includes(Role.salesmanAdmin);
	}

	isCustomerAdmin = (): boolean => {
		const { token } = this.getUserSessionCookies();
		const { realm_access: { roles } }: { realm_access: { roles: string[] } } = this.decodeJWT(token);
		return roles?.includes(Role.customerAdmin);
	}

	isCustomerPremium = (): boolean => {
		const { token } = this.getUserSessionCookies();
		const { realm_access: { roles } }: { realm_access: { roles: string[] } } = this.decodeJWT(token);
		return roles?.includes(Role.customerPremium);
	}

	getUserJwtData = (): UserData => {
		const { token } = this.getUserSessionCookies();
		const { family_name, given_name, email, name, preferred_username, sub } = this.decodeJWT(token);
		const userData: UserData = {
			id: sub,
			name: given_name,
			surname: family_name,
			fullName: name,
			email,
			userName: preferred_username,
		};
		return userData;
	}

	private decodeJWT = (token: string): any => {
		try {
			return jwt_decode(token);
		} catch(Error) {
			return null;
		}
	}
}
