import { EmployeeState } from "src/app/customer/employees/enums/employee-state.enum";
import { environment } from "src/environments/environment"

const baseUrl = environment.apiUrl;

export const ApiRoutes = {
	auth: {
		login: `${baseUrl}/backoffice/users/login`,
		loginWithNewPassword: `${baseUrl}/backoffice/users/loginNewPassword`,
		refreshToken: `${baseUrl}/backoffice/users/refresh`,
		signUp: `${baseUrl}/backoffice/workers/register`,
		resetPassword: `${baseUrl}/backoffice/worker/recoveryPassword`
	},
	dashboard: {
		locationStatistics: `${baseUrl}/backoffice/statistics/coords`
	},
	map: {
		points: `${baseUrl}/backoffice/map/points`,
		pointById: (id: string) => `${baseUrl}/backoffice/map/point/details?id=${id}`,
		feedbacksToVerify: `${baseUrl}/backoffice/feedback`,
		verifyFeedback: (id: string) => `${baseUrl}/backoffice/feedback/${id}/verified`,
		deleteFeedback: (id: string) => `${baseUrl}/backoffice/feedback/${id}`,
		mapPointsToVerify: `${baseUrl}/backoffice/parkings/reviews`,
		mapPointToVerifyDetails: (id: string) => `${baseUrl}/backoffice/parkings/reviews/${id}`,
		mergePoints: `${baseUrl}/backoffice/parkings/reviews/merge`,
		separatePoints: `${baseUrl}/backoffice/parkings/reviews/separate`,
		setParkingFor: `${baseUrl}/backoffice/map/point/availableFor`,
		deletePoint: (id: string) => `${baseUrl}/backoffice/parkings/reviews/${id}`
	},
	settings: {
		usersList: (start: number, max: number) => `${baseUrl}/backoffice/users/list?start=${start}&max=${max}`,
		appInfo: `${baseUrl}/backoffice/config/version`,
		userPremium: (id: string) => `${baseUrl}/backoffice/users/${id}/premium`
	},
	feedbacks: {
		list: `${baseUrl}/backoffice/appFeedback/list`
	},
	feed: {
		getFeed: `${baseUrl}/backoffice/feed`,
		deletePost: (id: string) => `${baseUrl}/backoffice/feed/${id}`,
		togglePinPost: (id: string) => `${baseUrl}/backoffice/feed/${id}/pin`,
		deleteComment: (id: string, postId: string) => `${baseUrl}/backoffice/feed/${postId}/comment/${id}`,
		uploadPostImage: `${baseUrl}/backoffice/feeds/images`,
		addPost: `${baseUrl}/backoffice/feeds`,
	},
	salesmans: {
		// getSalesmans: `${baseUrl}/backoffice/salesman`,
		getSalesmans: `${baseUrl}/backoffice/v2/salesman`,
		createTask: `${baseUrl}/backoffice/salesman/route`,
		getSalesmanTasks: (date: string, id: string) => `${baseUrl}/backoffice/tasks?date=${date}&userId=${id}`,
		confirmTask: (id: string) => `${baseUrl}/backoffice/salesman/route/${id}/accept`
	},
	chat: {
		discovery: `${baseUrl}/messenger/discovery`,
		metadata: (workspaceId: string) => `${baseUrl}/messenger/channels/info?workspaceId=${workspaceId}`,
		messages: (workspaceId: string, channelId: string, limit: number, fromTime: number) => `${baseUrl}/messenger/messages?workspaceId=${workspaceId}&channelId=${channelId}&limit=${limit}&fromTime=${fromTime}`,
		newMessage: `${baseUrl}/messenger/messages`,
		users: (workspaceId: string) => `${baseUrl}/messenger/users?workspaceId=${workspaceId}`,
		newChannel: `${baseUrl}/messenger/channels`,
		newThread: `${baseUrl}/messenger/threads`,
		channelByUserId: (workspaceId: string, userId: string) => `${baseUrl}/messenger/workspace/${workspaceId}/channel/withUser/${userId}`,
		deleteChannel: (workspaceId: string, channelId: string) => `${baseUrl}/messenger/workspace/${workspaceId}/channel/${channelId}`,
		addParticipants: `${baseUrl}/messenger/channel/join`,
		removeParticipants: `${baseUrl}/messenger/channel/unjoin`,
		translate: `${baseUrl}/messenger/translate`
	},
	customer: {
		map: {
			driversPoints: `${baseUrl}/backoffice/map/points`,
			drivers: `${baseUrl}/backoffice/map/drivers`,
			driverDetails: (id: string) => `${baseUrl}/backoffice/driver/${id}`,
			traffic: `https://api.tomtom.com/traffic/map/4/tile/flow/relative0/{z}/{x}/{y}.png?tileSize=512&key=${environment.tomTomToken}`,
			driverStatusReport: (id: string, from: string, to: string) => `${baseUrl}/coords/report/status?driverId=${id}&from=${from}&to=${to}`,
			driverCountriesReport: (id: string, from: string, to: string) => `${baseUrl}/coords/report/countries?driverId=${id}&from=${from}&to=${to}`,
			driverDistanceReport: (id: string, from: string, to: string) => `${baseUrl}/coords/report/travelled-distance?driverId=${id}&from=${from}&to=${to}`,
			driverHistoricCoords: (id: string, from: string, to: string) => `${baseUrl}/coords/historic/between?driverId=${id}&from=${from}&to=${to}`,
		},
		employees: {
			emploees: `${baseUrl}/backoffice/workers/list`,
			drivers: `${baseUrl}/backoffice/drivers/list`,
			newDriver: `${baseUrl}/backoffice/drivers/invite`,
			newEmployee: `${baseUrl}/backoffice/workers/invite`,
			deactivateEmployee: (id: string) => `${baseUrl}/backoffice/workers/${id}`,
			deleteEmployee: (id: string, status: EmployeeState) => `${baseUrl}/backoffice/workers/${id}?status=${status}`,
			deactivateDriver: (id: string) => `${baseUrl}/backoffice/drivers/${id}`,
			deleteDriver: (id: string, status: EmployeeState) => `${baseUrl}/backoffice/drivers/${id}?status=${status}`,
			reinviteEmployee: (id: string) => `${baseUrl}/backoffice/workers/invite/${id}`,
			reinviteDriver: (id: string) => `${baseUrl}/backoffice/drivers/invite/${id}`,
		},
		fleet: {
			fleet: `${baseUrl}/backoffice/fleet/vehicles`,
			vehicle: (id: string) => `${baseUrl}/backoffice/fleet/vehicles/${id}`,
			editVehicle: (id: string) => `${baseUrl}/backoffice/fleet/vehicles/${id}`,
			assignDriver: (id: string) => `${baseUrl}/backoffice/fleet/vehicles/${id}/assign`,
			unassignDriver: (id: string) => `${baseUrl}/backoffice/fleet/vehicles/${id}/unassign`,
			addTimetableItem: (id: string) => `${baseUrl}/backoffice/fleet/vehicles/${id}/reminders`,
			changeTimetableItemStatus: (vehicelId: string, timetableItemId: string) => `${baseUrl}/backoffice/fleet/vehicles/${vehicelId}/reminders/${timetableItemId}/status`,
			vehicleSet: `${baseUrl}/backoffice/fleet/vehicleCombinations`,
			returnVehicleSet: (id: string) => `${baseUrl}/backoffice/fleet/vehicleCombinations/${id}`,
			vehicleReports: (id: string) => `${baseUrl}/backoffice/fleet/vehicles/${id}/report`
		},
		settings: {
			subscriptionManagement: `${baseUrl}/backoffice/subscription/session`,
			subscriptionLimit: `${baseUrl}/backoffice/subscription/limit`,
			subscriptionUsage: `${baseUrl}/backoffice/subscription/usage`,
			clientReferenceId: `${baseUrl}/backoffice/subscription/stripeId`,
			editOrganizationName: `${baseUrl}/backoffice/organization`,
			editEmployeeName: (id: string) => `${baseUrl}/backoffice/workers/${id}`,
			editDriverName: (id: string) => `${baseUrl}/backoffice/drivers/${id}`,
		},
		tasks: {
			boards: `${baseUrl}/backoffice/task/boards`,
			getBoardLists: (boardId: string) => `${baseUrl}/backoffice/task/board/${boardId}/list`,
			getSingleCard: (cardId: string) => `${baseUrl}/backoffice/task/card/${cardId}`,
			getBoardListCards: (boardId: string, listId: string) => `${baseUrl}/backoffice/task/board/${boardId}/list/${listId}/cards`,
			createBoardList: (boardId: string) => `${baseUrl}/backoffice/task/board/${boardId}/lists`,
			editBoardList: (boardId: string, listId: string) => `${baseUrl}/backoffice/task/board/${boardId}/list/${listId}`,
			deleteBoardList: (boardId: string, listId: string) => `${baseUrl}/backoffice/task/board/${boardId}/list/${listId}`,
			createBoardListCard: (boardId: string, listId: string) => `${baseUrl}/backoffice/task/board/${boardId}/list/${listId}/cards`,
			setListOrder: (boardId: string, listId: string) => `${baseUrl}/backoffice/task/board/${boardId}/list/${listId}/cards/order`,
			setColumnsOrder: (boardId: string) => `${baseUrl}/backoffice/task/board/${boardId}/lists/order`,
			assignCardToList: (cardId: string) => `${baseUrl}/backoffice/task/card/${cardId}/assignToList`,
			toggleEmployeeToCardAssignment: (cardId: string) => `${baseUrl}/backoffice/task/card/${cardId}/assignWorker`,
			toggleDriverToCardAssignment: (cardId: string) => `${baseUrl}/backoffice/task/card/${cardId}/assignDriver`,
			changeTaskStatus: (cardId: string, taskId: string) => `${baseUrl}/backoffice/task/card/${cardId}/task/${taskId}`,
			deleteCard: (cardId: string) => `${baseUrl}/backoffice/task/card/${cardId}`,
			archiveCard: (cardId: string) => `${baseUrl}/backoffice/task/card/${cardId}/archive`,
			editCard: (cardId: string) => `${baseUrl}/backoffice/task/card/${cardId}`,
			addComment: (cardId: string) => `${baseUrl}/backoffice/task/card/${cardId}/comments`,
			addAttachment: (cardId: string) => `${baseUrl}/backoffice/task/card/${cardId}/attachments`,
			deleteAttachment: (cardId: string, attachmentId: string) => `${baseUrl}/backoffice/task/card/${cardId}/attachment/${attachmentId}`,
			archivedCards: `${baseUrl}/backoffice/task/cards/archived`,
			optimize: `${baseUrl}/backoffice/task/vrp`,
			acceptOptimization: `${baseUrl}/backoffice/task/vrp/accept`,
			updateDrvierDefaultAddress: `${baseUrl}/backoffice/task/vrp/driverStartAddress`,
			getDriverTasksSort: (boardId: string, driverId: string) => `${baseUrl}/backoffice/task/board/${boardId}/driver/${driverId}/cards/sort`
		},
		notifications: {
			getNotifications: `${baseUrl}/backoffice/me/notifications`,
			markAsRead: `${baseUrl}/backoffice/me/notifications/markAsRead`
		},
		contractors: {
			getContractors: `${baseUrl}/backoffice/contractors`,
			addContractor: `${baseUrl}/backoffice/contractors`,
			editContractor: (contractorId: string) => `${baseUrl}/backoffice/contractor/${contractorId}`,
			toggleContractorActiveStatus: (contractorId: string) => `${baseUrl}/backoffice/contractor/${contractorId}/active`,
			addAddress: (contractorId: string) => `${baseUrl}/backoffice/contractor/${contractorId}/addresses`,
			toggleAddressActiveStatus: (contractorId: string, addressId: string) => `${baseUrl}/backoffice/contractor/${contractorId}/address/${addressId}/active`,
			editAddress: (contractorId: string, addressId: string) => `${baseUrl}/backoffice/contractor/${contractorId}/address/${addressId}`,
			getContractorAddresses: (contractorId: string) => `${baseUrl}/backoffice/contractor/${contractorId}/addresses`,
			getContractorAddressById: (contractorId: string, addressId: string) => `${baseUrl}/backoffice/contractor/${contractorId}/address/${addressId}`,
			selectMainContractorAddress: (contractorId: string, addressId: string) => `${baseUrl}/backoffice/contractor/${contractorId}/address/${addressId}/main`
		},
		shipments: {
			getShipments: `${baseUrl}/backoffice/shipments`,
			addShipment: `${baseUrl}/backoffice/shipments`,
			editShipment: (id: string) => `${baseUrl}/backoffice/shipment/${id}`,
		}
	},
	user: {
		getProfile: `${baseUrl}/backoffice/profile/me`,
		setAvatar: (id: string) => `${baseUrl}/backoffice/workers/${id}/avatar`
	}
}