import { createReducer, on } from '@ngrx/store';
import { Shipment } from 'src/app/customer/shared/interfaces/shipment.interface';
import { addShipment, editShipment, setShipments } from './shipments.actions';

export interface ShipmentsState {
	shipments: Shipment[]
}

export const initialState: ShipmentsState = {
	shipments: [],
}

export const shipmentsReducer = createReducer<ShipmentsState>(
	initialState,
	on(setShipments, (state, { shipments }): any => ({
		...state,
		shipments
	})),
	on(addShipment, (state, { shipment }): any => ({
		...state,
		shipments: [...state.shipments, shipment]
	})),
	on(editShipment, (state, { id, shipment }) => ({
		...state,
		shipments: state.shipments.map(s => s.id === id ? { ...s, ...shipment } : s)
	})),
)