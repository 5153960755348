<div class="task-container" (click)="openAddNewCardModal()" [ngClass]="{ 'highlighted': cardIdToHighlight === card.cardId}">
	<div class="row top-row">
		<h3 class="name" [matTooltip]="card.name">{{ card.name }}</h3>
		<div class="attachments-info" *ngIf="card?.attachments && card.attachments?.length">
			<mat-icon>attach_file</mat-icon>
			<p class="count">{{ card.attachments?.length }}</p>
		</div>
		<div class="comments-info" *ngIf="card?.comments && card.comments?.length">
			<mat-icon>comment</mat-icon>
			<p class="count">{{ card.comments?.length }}</p>
		</div>
		<div class="assigned-employees">
			<div class="employee" *ngFor="let employee of assignedEmployees.slice(0, 2); let i = index;" [matTooltip]="employee.name">
				<img class="avatar" [src]="employee?.avatar ?? 'assets/icons/user.png'" />
			</div>
			<div class="more-employees" *ngIf="assignedEmployees.length > 2">
				+{{ assignedEmployees.length - 2 }}
			</div>
		</div>
	</div>
	<div *ngIf="isSimpleTransportCard(card)"
		class="row tasks-row"
	>
		<div class="task" [ngClass]="card.transportTasks[0]?.status">
			<mat-icon>download</mat-icon>
			<div class="task-info">
				<p>{{ getDeadline(0) }}</p>
				<p>{{ card.transportTasks[0]?.address?.freeformAddress }}</p>
			</div>
			<div class="task-status">
				<mat-icon (click)="changeTaskStatus($event, 0)">{{ card.transportTasks[0]?.status === 'scheduled' ? 'check_box_outline_blank' : 'check_box' }}</mat-icon>
			</div>
		</div>
		<div class="task-separator"></div>
		<div class="task" [ngClass]="card.transportTasks[1]?.status">
			<mat-icon>upload</mat-icon>
			<div class="task-info">
				<p>{{ getDeadline(1) }}</p>
				<p>{{ card.transportTasks[1]?.address?.freeformAddress }}</p>
			</div>
			<div class="task-status">
				<mat-icon (click)="changeTaskStatus($event, 1)">{{ card.transportTasks[1]?.status === 'scheduled' ? 'check_box_outline_blank' : 'check_box' }}</mat-icon>
			</div>
		</div>
	</div>
	<div class="row assigned-driver" *ngIf="assignedDriver">
		<img class="avatar" [src]=" assignedDriver.avatar ?? 'assets/icons/user.png'" />
		<h3 class="assigned-driver--name">{{ assignedDriver.name }}</h3>
	</div>
	<div class="row last-row">
		<div class="col">
			<div class="badge" *ngIf="cardBadge">{{ cardBadge }}</div>
			<p class="type">{{ 'shared.cardType.' + card.type | transloco }}</p>
		</div>
		<div class="actions-btn" (click)="$event.stopImmediatePropagation()" [matMenuTriggerFor]="cardActions" [matMenuTriggerData]="{employee: 'element'}">
			<mat-icon>more_horiz</mat-icon>
		</div>
	</div>
</div>

<mat-menu #cardActions="matMenu">
	<ng-template matMenuContent let-employee="employee">
		<button mat-menu-item (click)="archiveCard()">
			<mat-icon>archive</mat-icon>
			<span>{{ 'shared.button.archiveCard' | transloco }}</span>
		</button>
		<button mat-menu-item (click)="deleteCard()">
			<mat-icon>lock</mat-icon>
			<span>{{ 'shared.button.deleteCard' | transloco }}</span>
		</button>
		<button *ngIf="isSimpleTransportCard(card) && inSidebar" mat-menu-item (click)="goToCardOnMap()">
			<mat-icon>map</mat-icon>
			<span>{{ 'shared.button.findOnMap' | transloco }}</span>
		</button>
	</ng-template>
</mat-menu>