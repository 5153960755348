import { State } from "../app.state";
import { createSelector } from '@ngrx/store';
import { BoardState } from "./board.reducer";
import { Card } from "src/app/customer/board/interfaces/card.interface";

export const selectBoardState = (state: State) => state.tasks;

export const selectBoard = createSelector(
	selectBoardState,
	(state: BoardState) => state.board
);

export const selectLists = createSelector(
	selectBoardState,
	(state: BoardState) => state.lists
);

export const selectDriversVirtualLists = createSelector(
	selectBoardState,
	(state: BoardState) => state.driversVirtualLists
);

export const selectCards = createSelector(
	selectBoardState,
	(state: BoardState) => state.cards
);

export const selectCardsByIds = (ids: string[]) => createSelector(
	selectBoardState,
	(state: BoardState) => {
		const allCards: Card[] = state.cards.reduce(
			(cardsAcc, list) => cardsAcc.concat(list.cards),
			[] as Card[]
		);
		return allCards.filter(card => ids.includes(card.cardId));
	}
);

export const selectArchivedCards = createSelector(
	selectBoardState,
	(state: BoardState) => state.archive
);

export const selectUsersFilters = createSelector(
	selectBoardState,
	(state: BoardState) => state.usersFilters
);

export const selectCardTypeFilter = createSelector(
	selectBoardState,
	(state: BoardState) => state.cardTypeFilter
);

export const selectVisibleColumnsFilter = createSelector(
	selectBoardState,
	(state: BoardState) => state.visibleColumnsFilter
);

export const selectSearchBoardPhrase = createSelector(
	selectBoardState,
	(state: BoardState) => state.searchBoard
);

export const selectCardWithMetaById = (cardId: string) => createSelector(
	selectBoardState,
	(state: BoardState): { boardId: string; listId: string; card: Card } => {
		const entry = state.cards
			.map(({ listId, cards }) =>
				cards.map(card => ({ listId, card }))
			)
			.flat()
			.find(({ card }) => card.cardId === cardId);

		return {
			boardId: state.board?.boardId ?? '',
			listId: entry!.listId,
			card: entry!.card
		};
	}
);