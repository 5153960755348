import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Card, ShipmentsCard, ShipmentsTransportTask, SimpleCard, SimpleTransportCard, TransportTask, VirtualDriverTask, VirtualDriverTaskCard, VisitCard } from '../../../board/interfaces/card.interface';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddNewCardModalComponent } from '../../../board/components/add-new-card-modal/add-new-card-modal.component';
import * as moment from 'moment';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription, combineLatest, distinctUntilChanged, tap } from 'rxjs';
import { Driver } from 'src/app/customer/map/interfaces/driver.interface';
import { Store } from '@ngrx/store';
import { State } from 'src/app/state/app.state';
import { selectDrivers, selectEmployees } from 'src/app/state/employees/employees.selectors';
import { Employee } from 'src/app/customer/employees/interfaces/employee.interface';
import { BoardService } from '../../../board/services/board.service';
import { archiveCard, deleteCard, setSingleCard, setTaskStatus } from 'src/app/state/board/board.actions';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { CardType } from '../../../board/enums/card-type.enum';
import { selectClickedNotification } from 'src/app/state/notifications/notifications.selectors';
import { NotificationType } from '../../enums/notification-type.enum';
import { setClickedNotification } from 'src/app/state/notifications/notifications.actions';
import { goToCardOnMap } from 'src/app/state/map/map.actions';
import { Status } from '../../enums/status.enum';

@Component({
	selector: 'app-card-container',
	templateUrl: './card-container.component.html',
	styleUrls: ['./card-container.component.scss']
})
export class CardContainerComponent implements OnInit, OnDestroy, OnChanges {
	@Input() card: Card;
	@Input() listId: string;
	@Input() boardId: string;
	@Input() inSidebar: boolean = false;
	@Input() isVirtual: boolean = false;

	@Input() cardBadge: string | undefined;

	subscription: Subscription = new Subscription();
	assignedDriver: Driver | undefined;
	assignedEmployees: Employee[] = [];

	cardIdToHighlight: string | undefined;
	cardModalRef: MatDialogRef<AddNewCardModalComponent, any> | undefined

	private drivers: Driver[] = [];
	private employees: Employee[] = [];

	get visitCardStatus(): Status | undefined {
		return (this.card as VisitCard).status
	}

	constructor(
		private dialog: MatDialog,
		private transloco: TranslocoService,
		private store: Store<State>,
		private boardService: BoardService,
		private translocoService: TranslocoService,
		private cdr: ChangeDetectorRef,
	) { }

	ngOnInit(): void {
		this.getActiveEmployeesAndDrivers();
		this.checkClickedNotification();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!changes?.['card'].isFirstChange()) {
			const previousVal = (changes['card'].previousValue as Card);
			const currenVal = (changes['card'].currentValue as Card);
			if (previousVal.assignedDriver !== currenVal.assignedDriver) {
				this.setAssignedDriver(this.drivers)
			}
			if (previousVal.assignedWorkers !== currenVal.assignedWorkers) {
				this.setAssignedEmployees(this.employees);
			}
		}
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	openAddNewCardModal = (): void => {
		if (!this.cardModalRef) {
			this.cardModalRef = this.dialog.open(AddNewCardModalComponent, {
				disableClose: true,
				data: {
					boardId: this.boardId,
					listId: this.listId,
					card: this.card
				}
			})
			this.cardModalRef.afterClosed().subscribe(() => this.cardModalRef = undefined)
			if (this.card) {
				this.subscription.add(
					this.boardService.getCard(this.card.cardId).subscribe({
						next: (value) => {
							this.store.dispatch(setSingleCard({ listId: this.listId, card: value }))
						},
					})
				)
			}
		}
	}

	getDeadline = (taskIndex: number): string => {
		if (this.isSimpleTransportCard(this.card)) {
			return moment(this.card.transportTasks[taskIndex].deadline).locale(this.transloco.getActiveLang()).format('LLL');
		}
		return '';
	}

	getTask(card: Card, index: number): TransportTask | ShipmentsTransportTask | VirtualDriverTask | null {
		if (this.isSimpleTransportCard(card)) {
			return card.transportTasks[index] || null;
		}
		if (this.isShipmentsTransportCard(card)) {
			return card.shipmentsTransportTasks[index] || null;
		}
		if (this.isVirtualDriverTaskCard(card)) {
			return card.task
		}
		return null;
	}

	getCardDeadlines(card: Card, index: number): string {
		const task = this.getTask(card, index);
		if (!task && !this.isVisitCard(card) && !this.isSimpleCard(card)) {
			return '';
		}
		if (this.isSimpleTransportCard(card)) {
			return moment((task as TransportTask).deadline).locale(this.transloco.getActiveLang()).format('LLL');
		}
		if (this.isShipmentsTransportCard(card)) {
			return `${moment((task as ShipmentsTransportTask).timeslot.start).locale(this.transloco.getActiveLang()).format('LLL')} - 
			 ${moment((task as ShipmentsTransportTask).timeslot.end).locale(this.transloco.getActiveLang()).format('LLL')}`
		}
		if (this.isVirtualDriverTaskCard(card)) {
			if (card?.task?.deadline) {
				return moment((task as TransportTask).deadline).locale(this.transloco.getActiveLang()).format('LLL');
			}
			if (card?.task?.timeslot) {
				return `${moment((task as ShipmentsTransportTask).timeslot.start).locale(this.transloco.getActiveLang()).format('LLL')} - 
			 		${moment((task as ShipmentsTransportTask).timeslot.end).locale(this.transloco.getActiveLang()).format('LLL')}`
			}
		}
		if (this.isVisitCard(card)) {
			return `${moment(card.availableTimeslots[0].date).locale(this.transloco.getActiveLang()).format('LL')} - 
				${moment(card.availableTimeslots[card.availableTimeslots.length - 1].date).locale(this.transloco.getActiveLang()).format('LL')}`
		}
		if (this.isSimpleCard(card)) {
			return `${moment(card.from).locale(this.transloco.getActiveLang()).format('LL')} - 
				${moment(card.to).locale(this.transloco.getActiveLang()).format('LL')}`
		}
		return '';
	}

	getVisitTimeslots = (card: VisitCard): string =>
		`${card.availableTimeslots[0].startTime} - ${card.availableTimeslots[0].endTime}`

	changeTaskStatus = (event: MouseEvent, taskIdx: number): void => {
		event.stopImmediatePropagation();
	
		let taskId: string;
		let newStatus: Status;

		if (this.isVisitCard(this.card)) {
			taskId = this.card.cardId;
			newStatus = this.card.status === Status.scheduled ? Status.done : Status.scheduled;
		} else {
			const tasks = this.getTasks();
			const task = tasks?.[taskIdx];
			if (!task) return;
	
			taskId = task.taskId;
			newStatus = task.status === Status.scheduled ? Status.done : Status.scheduled;
		}
	
		this.subscription.add(
			this.boardService.changeTaskStatus(this.card.cardId, taskId, newStatus).pipe(
				tap(() => {
					if (this.isVisitCard(this.card)) {
						this.card.status = newStatus;
					} else {
						const task = this.getTasks()?.[taskIdx];
						if (task) task.status = newStatus;
					}
	
					this.store.dispatch(setTaskStatus({ cardId: this.card.cardId, taskId, status: newStatus }));
					this.cdr.markForCheck();
				})
			).subscribe()
		);
	};
	
	private getTasks(): (TransportTask | ShipmentsTransportTask | VirtualDriverTask)[] {
		if (this.isSimpleTransportCard(this.card)) return this.card.transportTasks;
		if (this.isShipmentsTransportCard(this.card)) return this.card.shipmentsTransportTasks;
		if (this.isVirtualDriverTaskCard(this.card)) return [this.card.task];
		return [];
	}

	deleteCard = (): void => {
		const dialogRef = this.openConfirmationDialog(this.translocoService.translate('dialog.deleteCard.info'));
		dialogRef.afterClosed().subscribe(confirmed => {
			if (confirmed) {
				this.subscription.add(
					this.boardService.deleteCard(this.card.cardId).subscribe(() => {
						this.store.dispatch(deleteCard({ listId: this.listId, cardId: this.card.cardId }))
					})
				)
			}
		})
	}

	archiveCard = (): void => {
		const dialogRef = this.openConfirmationDialog(this.translocoService.translate('dialog.archiveCard.info'));
		dialogRef.afterClosed().subscribe(confirmed => {
			if (confirmed) {
				this.subscription.add(
					this.boardService.archiveCard(this.card.cardId).subscribe((r) => {
						this.store.dispatch(archiveCard({ listId: this.listId, cardId: this.card.cardId }))
					})
				)
			}
		})
	}

	isSimpleTransportCard = (card: Card): card is SimpleTransportCard => {
		return card.type === CardType.simpleTransport;
	}

	isShipmentsTransportCard = (card: Card): card is ShipmentsCard => {
		return card.type === CardType.shipments;
	}

	isVisitCard = (card: Card): card is VisitCard => {
		return card.type === CardType.visit;
	}

	isSimpleCard = (card: Card): card is SimpleCard => {
		return card.type === CardType.simpleCard;
	}

	isVirtualDriverTaskCard = (card: Card): card is VirtualDriverTaskCard => {
		return card.type === CardType.virtualDriverTask;
	}

	goToCardOnMap = (): void => {
		this.store.dispatch(goToCardOnMap({ cardId: this.card.cardId }))
		this.store.dispatch(goToCardOnMap({ cardId: undefined }))
	}

	private checkClickedNotification = (): void => {
		this.subscription.add(
			this.store.select(selectClickedNotification).pipe(
				distinctUntilChanged((prev, curr) => prev === curr),
			).subscribe({
				next: (notification) => {
					if (notification &&
						notification?.type !== NotificationType.userDeletedCard &&
						notification?.type !== NotificationType.userArchivedCard &&
						notification?.attributes[1].type === 'card' &&
						notification?.attributes[1].id === this.card.cardId
					) {
						setTimeout(() => {
							this.cardIdToHighlight = notification.attributes[1].id
							this.store.dispatch(setClickedNotification({ notificationId: undefined }));
							this.openAddNewCardModal()
							setTimeout(() => {
								this.cardIdToHighlight = undefined
							}, 1500);
						}, 500)
					}
				},
			})
		)
	}

	private openConfirmationDialog = (info: string): MatDialogRef<ConfirmationDialogComponent, any> => {
		return this.dialog.open(ConfirmationDialogComponent, {
			autoFocus: false,
			data: { info }
		});
	}

	private getActiveEmployeesAndDrivers = (): void => {
		this.subscription.add(
			combineLatest([
				this.store.select((state) => selectEmployees(state)),
				this.store.select((state) => selectDrivers(state))
			]).subscribe(([employees, drivers]) => {
				this.drivers = drivers;
				this.employees = employees;
				this.setAssignedDriver(drivers)
				this.setAssignedEmployees(employees)
			})
		);
	}

	private setAssignedDriver = (drivers: Driver[]): void => {
		this.assignedDriver = drivers.find((driver) => driver.driverId === this.card.assignedDriver);
	}

	private setAssignedEmployees = (employees: Employee[]): void => {
		this.assignedEmployees = employees.filter(employee =>
			this.card.assignedWorkers.some((employeeId) => employeeId === employee.id)
		);
	}
}
