import { createAction, props } from "@ngrx/store";
import { Vehicle } from "../../customer/fleet/interfaces/vehicle.interface";
import { VehicleTimetableItem } from "src/app/customer/fleet/interfaces/vehicle-timetable-item.interface";
import { VehicleTimetableItemStatus } from "src/app/customer/fleet/enums/vehicle-timetable-item-status.enum";
import { VehicleSet } from "src/app/customer/fleet/interfaces/vehicle-set.interface";
import { VehicleReport } from "src/app/shared/interfaces/vehicle-report.interface";

export const setFleet = createAction(
	'[Fleet] Set Fleet', 
	props<{ vehicles: Vehicle[] }>()
);

export const setVehicleSets = createAction(
	'[Fleet] Set Vehicle Sets', 
	props<{ vehicleSets: VehicleSet[] }>()
);

export const addVehicle = createAction(
	'[Fleet] Add Vehicle', 
	props<{ vehicle: Vehicle }>()
);

export const updateVehicle = createAction(
	'[Fleet] Update Vehicle', 
	props<{ id: string, vehicle: Partial<Vehicle> }>()
);

export const updateVehicleOdometerValue = createAction(
	'[Fleet] Update Vehicle Odometer Value', 
	props<{ id: string, odometerValue: number }>()
);

export const archiveVehicle = createAction(
	'[Fleet] Archive Vehicle', 
	props<{ vehicleId: string }>()
);

export const setSelectedVehiclePreview = createAction(
	'[Fleet] Set Selected Vehicle Preview', 
	props<{ vehicle: Vehicle | null }>()
);

export const addVehicleTimetableItem = createAction(
	'[Fleet] Add Vehicle Timetable Item', 
	props<{ vehicleId: string, item: VehicleTimetableItem }>()
);

export const changeVehicleTimetableItemStatus = createAction(
	'[Fleet] Change Vehicle Timetable Item Status', 
	props<{ vehicleId: string, itemId: string, status: VehicleTimetableItemStatus }>()
);

export const returnVehicleSet = createAction(
	'[Fleet] Return Vehicle Set', 
	props<{ vehicleSet: VehicleSet }>()
);

export const setVehiclesReports = createAction(
	'[Fleet] Set Vehicles Reports', 
	props<{ vehiclesReports: VehicleReport[] }>()
);