import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatIconModule } from '@angular/material/icon'
import { MatButtonModule } from '@angular/material/button'
import { MatSidenavModule } from '@angular/material/sidenav';
import { IconButtonComponent } from './components/icon-button/icon-button.component'
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { SnackbarService } from './services/snackbar.service';
import { LoadingComponent } from './components/loading/loading.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker'
import { DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { AddTaskComponent } from './components/add-task/add-task.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { NgxEditorModule } from 'ngx-editor';
import { TranslocoModule } from '@ngneat/transloco';
import { LanguageSelectComponent } from './components/language-select/language-select.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MessageComponent } from './components/message/message.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { CardContainerComponent } from '../customer/shared/components/card-container/card-container.component';
import { AddNewChatChannelComponent } from './components/add-new-chat-channel/add-new-chat-channel.component';
import { InputComponent } from './components/input/input.component';
import { UsersAutocompleteComponent } from './components/users-autocomplete/users-autocomplete.component';
import { ManageChatParticipantsComponent } from './components/manage-chat-participants/manage-chat-participants.component';
import { CustomDateAdapter } from './configuration/adapters/mat-date.adapter';
import { TruncatePipe } from './pipes/truncate.pipe';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TrimVehicleIdPipe } from './pipes/trim-vehicle-id.pipe'
import { MatGridListModule } from '@angular/material/grid-list';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { VehicleAutocompleteComponent } from './components/vehicle-autocomplete/vehicle-autocomplete.component';


@NgModule({
	declarations: [
		IconButtonComponent,
  		LoadingComponent,
    	ConfirmationDialogComponent,
     	AddTaskComponent,
     	LanguageSelectComponent,
		MessageComponent,
		AvatarComponent,
		CardContainerComponent,
 		AddNewChatChannelComponent,
   		InputComponent,
     	UsersAutocompleteComponent,
 	    ManageChatParticipantsComponent,
		TruncatePipe,
 		TrimVehicleIdPipe,
		VehicleAutocompleteComponent,
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatIconModule,
		MatButtonModule,
		MatSidenavModule,
		MatCardModule,
		MatCheckboxModule,
		MatRadioModule,
		DragDropModule,
		MatSnackBarModule,
		MatTableModule,
		MatSortModule,
		MatPaginatorModule,
		MatProgressSpinnerModule,
		MatExpansionModule,
		MatDialogModule,
		MatTabsModule,
		MatListModule,
		MatSelectModule,
		MatDatepickerModule,
		MatNativeDateModule,
		NgxMaterialTimepickerModule,
		MaterialFileInputModule,
		NgxEditorModule,
		TranslocoModule,
		MatTooltipModule,
		MatMenuModule,
		MatAutocompleteModule,
		MatSlideToggleModule,
		MatGridListModule,
		MatChipsModule,
		MatStepperModule
	],
	exports: [
		IconButtonComponent,
		ConfirmationDialogComponent,
		ReactiveFormsModule,
		FormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatIconModule,
		MatButtonModule,
		MatSidenavModule,
		MatCardModule,
		MatCheckboxModule,
		MatRadioModule,
		DragDropModule,
		MatSnackBarModule,
		MatTableModule,
		MatSortModule,
		MatPaginatorModule,
		MatProgressSpinnerModule,
		LoadingComponent,
		MatExpansionModule,
		MatDialogModule,
		MatTabsModule,
		MatListModule,
		MatSelectModule,
		MatDatepickerModule,
		MatNativeDateModule,
		NgxMaterialTimepickerModule,
		MaterialFileInputModule, 
		NgxEditorModule,
		TranslocoModule,
		LanguageSelectComponent,
		MatMenuModule,
		MatTooltipModule,
		MessageComponent,
		AvatarComponent,
		CardContainerComponent,
		MatAutocompleteModule,
		InputComponent,
		UsersAutocompleteComponent,
		TruncatePipe,
		MatSlideToggleModule,
		TrimVehicleIdPipe,
		MatGridListModule,
		VehicleAutocompleteComponent,
		MatChipsModule,
		MatStepperModule
	],
	providers: [
		SnackbarService,
		{ provide: MAT_DATE_LOCALE, useValue: 'pl-PL' },
		{ provide: DateAdapter, useClass: CustomDateAdapter },
		
	]
})
export class SharedModule { }
