import { createReducer, on } from '@ngrx/store';
import { goToCardOnMap, setDriverHistoricCoords, setDriversPoints, setSelectedDriver, setSelectedDriverCardsByDate, updateDriverCoords } from './map.actions';
import { DriverDetails } from 'src/app/customer/map/interfaces/driver.interface';
import { DriverPoint } from 'src/app/customer/map/interfaces/driver-point.interface';
import { DriverStatus } from 'src/app/customer/map/enums/driver-status.enum';
import { DriverHistoricCoord } from 'src/app/customer/map/interfaces/driver-historic-coord.interface';
import { Card } from 'src/app/customer/board/interfaces/card.interface';
import { setTaskStatus } from '../board/board.actions';

export interface MapState {
	selectedDriver: DriverDetails | null;
	driversPoints: DriverPoint[];
	driverHistoricCoords: DriverHistoricCoord[];
	selectedDriverCardsByDate: Card[];
	goToCardOnMapId: string | undefined;
}

export const initialState: MapState = {
	selectedDriver: null,
	driversPoints: [],
	driverHistoricCoords: [],
	selectedDriverCardsByDate: [],
	goToCardOnMapId: undefined
}

export const mapReducer = createReducer<MapState>(
	initialState,
	on(setSelectedDriver, (state, { selectedDriver }): any => ({
		...state,
		selectedDriver
	})),
	on(updateDriverCoords, (state, { driverId, coords, driver }) => {
		const driverPointIdx = state.driversPoints.findIndex((d) => d.driverId === driverId);
		const driversPoints = state.driversPoints;

		if (driverPointIdx >= 0) {
			driversPoints[driverPointIdx].coords = {
				latitude: coords.latitude,
				longitude: coords.longitude
			};
		} else if (driver) {
			driversPoints.push(
				{
					avatar: driver?.avatar ?? '',
					coords,
					driverId: driver.driverId,
					name: driver?.name ?? '',
					status: DriverStatus.PAUSE,
					timestamp: new Date(),
					type: 'driver'
				}
			)
		}

		return {
			...state,
			driversPoints: [...driversPoints],
		};
	}),
	on(setDriversPoints, (state, { driversPoints }): any => ({
		...state,
		driversPoints
	})),
	on(setDriverHistoricCoords, (state, { driverHistoricCoords }): any => ({
		...state,
		driverHistoricCoords
	})),
	on(setSelectedDriverCardsByDate, (state, { cards }): any => ({
		...state,
		selectedDriverCardsByDate: cards
	})),
	on(goToCardOnMap, (state, { cardId }): any => ({
		...state,
		goToCardOnMapId: cardId
	})),
	on(setTaskStatus, (state, { cardId, taskId, status }): MapState => ({
		...state,
		selectedDriverCardsByDate: state.selectedDriverCardsByDate.map(card => {
			if ('transportTasks' in card && card.cardId === cardId) {
				return {
					...card,
					tasks: card.transportTasks.map(task =>
						task.taskId === taskId ? { ...task, status } : task
					)
				};
			}
			return card;
		})
	}))
)