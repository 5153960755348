import { createAction, props } from '@ngrx/store';
import { Address } from 'src/app/customer/board/interfaces/card.interface';
import { Employee } from 'src/app/customer/employees/interfaces/employee.interface';
import { Driver, Speed } from 'src/app/customer/map/interfaces/driver.interface';

export const setEmployees = createAction(
	'[Employees] Set employees',
	props<{ employees: Employee[] }>()
)

export const setDrivers = createAction(
	'[Employees] Set drivers',
	props<{ drivers: Driver[] }>()
)

export const setDriver = createAction(
	'[Employees] Set driver',
	props<{ driver: Driver, invitationId: string }>()
)

export const updateDriverSpeed = createAction(
	'[Employees] Update driver speed',
	props<{ driverId: string, speedValue: number, speedUnit: string }>()
)

export const updateEmployeeName = createAction(
	'[Employees] Update employee name',
	props<{ id: string, newName: string }>()
)

export const updateDrivereName = createAction(
	'[Employees] Update driver name',
	props<{ id: string, newName: string }>()
)

export const updateDriverStartAddress = createAction(
	'[Employees] Update driver start address',
	props<{ id: string, startAddress: Address | null }>()
)