import { State } from "../app.state";
import { createSelector } from '@ngrx/store';
import { ShipmentsState } from "./shipments.reducer";

export const selectShipmentsState = (state: State) => state.shipments;

export const selectShipments = createSelector(
	selectShipmentsState,
	(state: ShipmentsState) => state.shipments
);

export const selectUniqueTags = createSelector(
	selectShipmentsState,
	(state: ShipmentsState) => {
		const allTags = state.shipments
			.flatMap(shipment => shipment.details.tags || [])
			.filter((tag, index, self) => self.indexOf(tag) === index);
		return allTags;
	}
);