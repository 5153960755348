import { State } from "../app.state";
import { createSelector } from '@ngrx/store';
import { FleetState } from "./fleet.reducer";

export const selectFleetState = (state: State) => state.fleet;

export const selectAllVehicles = createSelector(
	selectFleetState,
	(state: FleetState) => state.vehicles
);

export const selectVehicleSets = createSelector(
	selectFleetState,
	(state: FleetState) => state.vehicleSets
);

export const selectVehicleById = (vehicleId: string) => createSelector(
	selectFleetState,
	(state: FleetState) => state.vehicles.find(vehicle => vehicle.id === vehicleId)
);

export const isVehicleAssigned = (vehicleId: string) => createSelector(
	selectFleetState,
	(state: FleetState) => Boolean(state.assignedVehiclesIds.find(id => id === vehicleId))
);

export const selectSelectedVehiclePreview = createSelector(
	selectFleetState,
	(state: FleetState) => state.selectedVehiclePreview
);

export const selectVehicleReportsById = (vehicleId: string) => createSelector(
	selectFleetState,
	(state: FleetState) => state.vehiclesReports.filter(report => report.vehicleId === vehicleId)
);