import { createReducer, on } from '@ngrx/store';
import { setDriver, setDrivers, setEmployees, updateDrivereName, updateDriverSpeed, updateDriverStartAddress, updateEmployeeName } from './employees.actions';
import { Employee } from 'src/app/customer/employees/interfaces/employee.interface';
import { Driver } from 'src/app/customer/map/interfaces/driver.interface';

export interface EmployeesState {
	employees: Employee[];
	drivers: Driver[];
}

export const initialState: EmployeesState = {
	employees: [],
	drivers: [],
}

export const employeesReducer = createReducer<EmployeesState>(
	initialState,
	on(setEmployees, (state, { employees }): any => ({
		...state,
		employees
	})),
	on(setDrivers, (state, { drivers }): any => ({
		...state,
		drivers,
	})),
	on(setDriver, (state, { driver, invitationId }) => {
		const driverIdx = state.drivers.findIndex((d) => d.driverId === driver.driverId || d.driverId === invitationId);
		const drivers = state.drivers;

		if (driverIdx >= 0) {
			drivers[driverIdx].status = driver.status;
			drivers[driverIdx].state = driver.state;
			drivers[driverIdx].driverId = driver.driverId
		}

		return {
			...state,
			drivers,
		};
	}),
	on(updateDriverSpeed, (state, { driverId, speedValue, speedUnit }) => {
		const driverIdx = state.drivers.findIndex((driver) => driver.driverId === driverId);
		const driver = state.drivers[driverIdx];
		const drivers = state.drivers;

		if (driverIdx >= 0 && driver) {
			driver.speed = { value: speedValue, unit: speedUnit }
			drivers[driverIdx] = driver;
		}
	
		return {
			...state,
			drivers,
		};
	}),
	on(updateEmployeeName, (state, { id, newName }) => {
		const employeeIdx = state.employees.findIndex((employee) => employee.id === id);
		const employee = state.employees[employeeIdx];
		const employees = state.employees;

		if (employeeIdx >= 0 && employee) {
			employee.name = newName
			employees[employeeIdx] = employee;
		}

		return {
			...state,
			employees,
		};
	}),
	on(updateDrivereName, (state, { id, newName }) => {
		const driverIdx = state.drivers.findIndex((driver) => driver.driverId === id);
		const driver = state.drivers[driverIdx];
		const drivers = state.drivers;

		if (driverIdx >= 0 && driver) {
			driver.name = newName
			drivers[driverIdx] = driver;
		}
	
		return {
			...state,
			drivers,
		};
	}),
	on(updateDriverStartAddress, (state, { id, startAddress }) => {
		const driverIdx = state.drivers.findIndex((driver) => driver.driverId === id);
		const driver = state.drivers[driverIdx];
		const drivers = state.drivers;

		if (driverIdx >= 0 && driver) {
			driver.startAddress = startAddress
			drivers[driverIdx] = driver;
		}
	
		return {
			...state,
			drivers,
		};
	}),
)