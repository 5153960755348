import { createReducer, on } from '@ngrx/store';
import { setFleet, addVehicle, updateVehicle, archiveVehicle, updateVehicleOdometerValue, setSelectedVehiclePreview, addVehicleTimetableItem, changeVehicleTimetableItemStatus, setVehicleSets, returnVehicleSet, setVehiclesReports } from './fleet.actions';
import { Vehicle } from "../../customer/fleet/interfaces/vehicle.interface";
import { VehicleSet } from 'src/app/customer/fleet/interfaces/vehicle-set.interface';
import { VehicleReport } from 'src/app/shared/interfaces/vehicle-report.interface';

export interface FleetState {
	vehicles: Vehicle[];
	selectedVehiclePreview: Vehicle | null;
	vehicleSets: VehicleSet[];
	assignedVehiclesIds: string[];
	vehiclesReports: VehicleReport[];
}

export const initialState: FleetState = {
	vehicles: [],
	selectedVehiclePreview: null,
	vehicleSets: [],
	assignedVehiclesIds: [],
	vehiclesReports: [],
};

export const fleetReducer = createReducer<FleetState>(
	initialState,
	on(setFleet, (state, { vehicles }): FleetState => ({
		...state,
		vehicles
	})),
	on(setVehicleSets, (state, { vehicleSets }): FleetState => ({
		...state,
		vehicleSets,
		assignedVehiclesIds: vehicleSets.flatMap((vs) => [vs.engineVehicleId, vs?.trailerId ?? ''].filter(id => id))
	})),
	on(addVehicle, (state, { vehicle }): FleetState => ({
		...state,
		vehicles: [vehicle, ...state.vehicles],
	})),
	on(updateVehicle, (state, { id, vehicle }): FleetState => {
		const vehicleIdx = state.vehicles.findIndex((v) => v.id === id);
		if (vehicleIdx >= 0) {
			const updatedVehicles = [...state.vehicles];
			updatedVehicles[vehicleIdx] = { ...updatedVehicles[vehicleIdx], ...vehicle };
			return {
				...state,
				vehicles: updatedVehicles,
			};
		}
		return state;
	}),
	on(updateVehicleOdometerValue, (state, { id, odometerValue }): FleetState => {
		const vehicleIdx = state.vehicles.findIndex((v) => v.id === id);
		if (vehicleIdx >= 0) {
			const updatedVehicles = [...state.vehicles];
			updatedVehicles[vehicleIdx] = { ...updatedVehicles[vehicleIdx], odometer: { ...updatedVehicles[vehicleIdx].odometer, value: odometerValue } };
			return {
				...state,
				vehicles: updatedVehicles,
			};
		}
		return state;
	}),
	on(archiveVehicle, (state, { vehicleId }): FleetState => {
		const updatedVehicles = state.vehicles.filter(vehicle => vehicle.id !== vehicleId);
		return {
			...state,
			vehicles: updatedVehicles,
		};
	}),
	on(returnVehicleSet, (state, { vehicleSet }): FleetState => {
		const updatedVehicleSets = state.vehicleSets.filter(vs => vs.id !== vehicleSet.id);
		const updatedAssignedVehiclesIds = state.assignedVehiclesIds.filter(id => id !== vehicleSet.engineVehicleId && id !== vehicleSet?.trailerId)
		const currentAssignedVehicles = state.vehicles.filter(v => v.assignedUserId === vehicleSet.driverId);
		const updatedVehicles = state.vehicles.map(v => 
			currentAssignedVehicles.includes(v) ? { ...v, assignedUserId: null } : v
		);
		return {
		...state,
			vehicleSets: updatedVehicleSets,
			assignedVehiclesIds: updatedAssignedVehiclesIds,
			vehicles: updatedVehicles
		};
	}),
	on(setSelectedVehiclePreview, (state, { vehicle }): FleetState => ({
		...state,
		selectedVehiclePreview: vehicle
	})),
	on(addVehicleTimetableItem, (state, { vehicleId, item }): FleetState => {
		const vehicleIdx = state.vehicles.findIndex((v) => v.id === vehicleId);
		if (vehicleIdx >= 0) {
			const updatedVehicles = [...state.vehicles];
			updatedVehicles[vehicleIdx] = { ...updatedVehicles[vehicleIdx], reminders: [...updatedVehicles[vehicleIdx].reminders, item] };
			return {
				...state,
				vehicles: updatedVehicles,
				selectedVehiclePreview: updatedVehicles[vehicleIdx]
			};
		}
		return state;
	}),
	on(changeVehicleTimetableItemStatus, (state, { vehicleId, itemId, status }): FleetState => {
		const vehicleIdx = state.vehicles.findIndex((v) => v.id === vehicleId);

		if (vehicleIdx >= 0) {
			const updatedVehicles = [...state.vehicles];
			const vehicleTimetableItemIdx = updatedVehicles[vehicleIdx].reminders.findIndex((v) => v.id === itemId);

			if (vehicleTimetableItemIdx >= 0) {
				const updatedReminders = [...updatedVehicles[vehicleIdx].reminders];
				updatedReminders[vehicleTimetableItemIdx] = {
					...updatedReminders[vehicleTimetableItemIdx],
					status: status
				};

				updatedVehicles[vehicleIdx] = {
					...updatedVehicles[vehicleIdx],
					reminders: updatedReminders
				};

				return {
					...state,
					vehicles: updatedVehicles,
					selectedVehiclePreview: updatedVehicles[vehicleIdx]
				};
			}
			return state;
		}
		return state;
	}),
	on(setVehiclesReports, (state, { vehiclesReports }): FleetState => ({
		...state,
		vehiclesReports
	})),
);