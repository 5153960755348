import { State } from "../app.state";
import { createSelector } from '@ngrx/store';
import { ContractorsState } from "./contractors.reducer";

export const selectContractorsState = (state: State) => state.contractors;

export const selectContractors = createSelector(
	selectContractorsState,
	(state: ContractorsState) => state.contractors
);

export const selectContractorAddresses = (id: string) => createSelector(
	selectContractorsState,
	(state: ContractorsState) => state.contractors.find((contractor) => contractor.id === id)?.addresses
);

export const selectContractorById = (id: string) => createSelector(
	selectContractorsState,
	(state: ContractorsState) => state.contractors.find((c) => c.id === id)
);
