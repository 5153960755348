<div class="users-autocomplete-container">
	<form [formGroup]="usersFormGroup" class="user-autocomplete-container">
		<div class="assigned-users-container" *ngIf="assignedUsers && assignedUsers.length > 0">
			<div class="assigned-user"
				[ngClass]="{
					'assigned-user--driver': user?.driverId,
					'assigned-user--disabled': user?.id === (userAdminId | async) && !inBoard || disabled,
					'disabled': disabled
				}"
				*ngFor="let user of assignedUsers"
				(click)="toggleUserAssignment(user)"
			>
				<mat-icon>{{ user?.driverId ? 'local_shipping' : 'person' }}</mat-icon>
				<span>{{ user.name }}</span>
				<mat-icon>close</mat-icon>
			</div>
		</div>
		<ng-container *ngIf="driverAssignment">
			<input *ngIf="assignedUsers.length <= 0" #userAutocompleteInput class="user-select-input" formControlName="users" [placeholder]="(inFilters ? ('shared.placeholder.filterByUsers' | transloco) : ('shared.placeholder.assignEmployee' | transloco)) + (required ? ' *' : '')" />
		</ng-container>
		<ng-container *ngIf="!driverAssignment">
			<input #userAutocompleteInput class="user-select-input" formControlName="users" [placeholder]="(inFilters ? ('shared.placeholder.filterByUsers' | transloco) : ('shared.placeholder.assignEmployee' | transloco)) + (required ? ' *' : '')" />
		</ng-container>
		<ul *ngIf="(filteredUsers | async)?.length && usersListVisible" class="predictions-container">
			<li class="prediction user" (click)="toggleUserAssignment(employee)" *ngFor="let employee of filteredUsers | async">
				<img [src]="employee?.avatar ?? 'assets/icons/user.png'">
				{{ employee.name }}
				<mat-icon>{{ employee?.driverId ? 'local_shipping' : 'person' }}</mat-icon>
			</li>
		</ul>
	</form>
	<div class="actions-container" *ngIf="withActions">
		<button class="add-all-btn" (click)="addAll('drivers')">
			<mat-icon>add</mat-icon>
			{{ 'shared.button.allDrivers' | transloco }}
		</button>
		<button class="add-all-btn" (click)="addAll('employees')">
			<mat-icon>add</mat-icon>
			{{ 'shared.button.allEmployees' | transloco }}
		</button>
	</div>
</div>
