<div class="task-container" (click)="openAddNewCardModal()" [ngClass]="{ 'highlighted': cardIdToHighlight === card.cardId}">
	<ng-container *ngIf="!isVirtualDriverTaskCard(card); else virtualDriverTaskCard;">
		<div class="row top-row" *ngIf="!isVirtual">
			<h3 class="name" [matTooltip]="card.name">{{ card.name }}</h3>
			<div class="attachments-info" *ngIf="card?.attachments && card.attachments?.length">
				<mat-icon>attach_file</mat-icon>
				<p class="count">{{ card.attachments?.length }}</p>
			</div>
			<div class="comments-info" *ngIf="card?.comments && card.comments?.length">
				<mat-icon>comment</mat-icon>
				<p class="count">{{ card.comments?.length }}</p>
			</div>
			<div class="assigned-employees">
				<div class="employee" *ngFor="let employee of assignedEmployees.slice(0, 2); let i = index;" [matTooltip]="employee.name">
					<img class="avatar" [src]="employee?.avatar ?? 'assets/icons/user.png'" />
				</div>
				<div class="more-employees" *ngIf="assignedEmployees.length > 2">
					+{{ assignedEmployees.length - 2 }}
				</div>
			</div>
		</div>
		<div *ngIf="isSimpleTransportCard(card) || isShipmentsTransportCard(card)"
			class="row tasks-row"
		>
			<div class="task" [ngClass]="getTask(card, 0)?.status">
				<mat-icon>download</mat-icon>
				<div class="task-info">
					<p>{{ getCardDeadlines(card, 0) }}</p>
					<p>{{ getTask(card, 0)?.address?.freeformAddress }}</p>
				</div>
				<div class="task-status">
					<mat-icon (click)="changeTaskStatus($event, 0)">{{ getTask(card, 0)?.status === 'scheduled' ? 'check_box_outline_blank' : 'check_box' }}</mat-icon>
				</div>
			</div>
			<div class="task-separator"></div>
			<div class="task" [ngClass]="getTask(card, 1)?.status">
				<mat-icon>upload</mat-icon>
				<div class="task-info">
					<p>{{ getCardDeadlines(card, 1) }}</p>
					<p>{{ getTask(card, 1)?.address?.freeformAddress }}</p>
				</div>
				<div class="task-status">
					<mat-icon (click)="changeTaskStatus($event, 1)">{{ getTask(card, 1)?.status === 'scheduled' ? 'check_box_outline_blank' : 'check_box' }}</mat-icon>
				</div>
			</div>
		</div>
		<div *ngIf="isVisitCard(card)" class="row tasks-row">
			<div class="task">
				<mat-icon>location_on</mat-icon>
				<div class="task-info">
					<p>{{ getCardDeadlines(card, 0) }}</p>
					<p>{{ getVisitTimeslots(card) }}</p>
					<p>{{ card.address?.freeformAddress }}</p>
				</div>
				<div class="task-status">
					<mat-icon (click)="changeTaskStatus($event, 0)">{{ visitCardStatus === 'scheduled' ? 'check_box_outline_blank' : 'check_box' }}</mat-icon>
				</div>
			</div>
		</div>
		<div *ngIf="isSimpleCard(card)" class="row tasks-row">
			<div class="task">
				<mat-icon>calendar_month</mat-icon>
				<div class="task-info">
					<p>{{ getCardDeadlines(card, 0) }}</p>
				</div>
			</div>
		</div>
		<div class="row assigned-driver" *ngIf="assignedDriver && !isVirtual">
			<img class="avatar" [src]=" assignedDriver.avatar ?? 'assets/icons/user.png'" />
			<h3 class="assigned-driver--name">{{ assignedDriver.name }}</h3>
		</div>
		<div class="row last-row">
			<div class="col">
				<div class="badge" *ngIf="cardBadge">{{ cardBadge }}</div>
				<p class="type">{{ 'shared.cardType.' + card.type | transloco }}</p>
			</div>
			<div class="actions-btn" *ngIf="!isVirtual" (click)="$event.stopImmediatePropagation()" [matMenuTriggerFor]="cardActions" [matMenuTriggerData]="{employee: 'element'}">
				<mat-icon>more_horiz</mat-icon>
			</div>
		</div>
	</ng-container>
</div>

<ng-template #virtualDriverTaskCard>
	<ng-container *ngIf="getTask(card, 0) as task;">
		<h3 class="name virtual-card-name" [matTooltip]="card.name">{{ card.name }}</h3>
		<div class="row tasks-row" >
			<div class="task" [ngClass]="task?.status">
				<mat-icon>{{ task.type === 'pickup' ? 'download' : 'upload' }}</mat-icon>
				<div class="task-info">
					<p>{{ getCardDeadlines(card, 0) }}</p>
					<p>{{ task?.address?.freeformAddress }}</p>
				</div>
				<div class="task-status">
					<mat-icon (click)="changeTaskStatus($event, 0)">{{ task?.status === 'scheduled' ? 'check_box_outline_blank' : 'check_box' }}</mat-icon>
				</div>
			</div>
		</div>
	</ng-container>
</ng-template>

<mat-menu #cardActions="matMenu">
	<ng-template matMenuContent let-employee="employee">
		<button mat-menu-item (click)="archiveCard()">
			<mat-icon>archive</mat-icon>
			<span>{{ 'shared.button.archiveCard' | transloco }}</span>
		</button>
		<button mat-menu-item (click)="deleteCard()">
			<mat-icon>lock</mat-icon>
			<span>{{ 'shared.button.deleteCard' | transloco }}</span>
		</button>
		<button *ngIf="isSimpleTransportCard(card) && inSidebar" mat-menu-item (click)="goToCardOnMap()">
			<mat-icon>map</mat-icon>
			<span>{{ 'shared.button.findOnMap' | transloco }}</span>
		</button>
	</ng-template>
</mat-menu>