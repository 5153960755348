import { createAction, props } from '@ngrx/store';
import { Shipment } from 'src/app/customer/shared/interfaces/shipment.interface';

export const setShipments = createAction(
	'[Shipments] Set shipments',
	props<{ shipments: Shipment[] }>()
)

export const addShipment = createAction(
	'[Shipments] Add shipment',
	props<{ shipment: Shipment }>()
)

export const editShipment = createAction(
	'[Shipments] Edit shipment',
	props<{ id: string, shipment: Shipment }>()
)