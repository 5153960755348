import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiRoutes } from 'src/app/shared/configuration/api-routes';
import { Board } from '../interfaces/board.interface';
import { List } from '../interfaces/list.interface';
import { Attachment, Comment } from '../interfaces/card.interface';
import { Card } from '../interfaces/card.interface';
import { Optimization } from '../interfaces/optimization.interface';
import { OptimizationResult } from '../interfaces/optimization-result.interface';
import { DriverDefaultAddressUpdate } from '../interfaces/driver-default-address-update.interface';
import { DriverVirtualTaskSort } from '../interfaces/driver-virtual-task-sort.interface';
import { CardType } from '../enums/card-type.enum';
import { Status } from '../../shared/enums/status.enum';

@Injectable({
	providedIn: 'root'
})
export class BoardService {
	private boardRefresh: EventEmitter<void> = new EventEmitter<void>();
	public boardRefresh$: Observable<void> = this.boardRefresh.asObservable();

	constructor(
		private http: HttpClient,
	) { }

	emitBoardRefresh = (): void => {
		this.boardRefresh.emit();
	}

	getBoards = (): Observable<Board[]> =>
		this.http.get<Board[]>(ApiRoutes.customer.tasks.boards);

	getBoardLists = (boardId: string): Observable<List[]> =>
		this.http.get<List[]>(ApiRoutes.customer.tasks.getBoardLists(boardId))

	getBoardListCards = (boardId: string, listId: string): Observable<Card[]> =>
		this.http.get<Card[]>(ApiRoutes.customer.tasks.getBoardListCards(boardId, listId))

	getArchivedCards = (): Observable<Card[]> =>
		this.http.get<Card[]>(ApiRoutes.customer.tasks.archivedCards)

	createBoard = (name: string): Observable<{ boardId: string }> =>
		this.http.post<{ boardId: string }>(ApiRoutes.customer.tasks.boards, { name });

	createBoardList = (name: string, boardId: string): Observable<{ listId: string }> =>
		this.http.post<{ listId: string }>(ApiRoutes.customer.tasks.createBoardList(boardId), { name })

	editBoardList = (name: string, boardId: string, listId: string): Observable<{ listId: string }> =>
		this.http.put<{ listId: string }>(ApiRoutes.customer.tasks.editBoardList(boardId, listId), { name })

	deleteBoardList = (boardId: string, listId: string): Observable<any> =>
		this.http.delete<any>(ApiRoutes.customer.tasks.deleteBoardList(boardId, listId))

	createCard = (boardId: string, listId: string, newCard: Card): Observable<{ cardId: string }> =>
		this.http.post<{ cardId: string }>(ApiRoutes.customer.tasks.createBoardListCard(boardId, listId), { ...newCard })

	deleteCard = (cardId: string): Observable<{}> =>
		this.http.delete<{}>(ApiRoutes.customer.tasks.deleteCard(cardId))

	archiveCard = (cardId: string): Observable<{}> =>
		this.http.put<{}>(ApiRoutes.customer.tasks.archiveCard(cardId), {})

	editCard = (cardId: string, card: Card): Observable<{}> =>
		this.http.put<{}>(ApiRoutes.customer.tasks.editCard(cardId), { ...card })

	getCard = (cardId: string): Observable<Card> =>
		this.http.get<Card>(ApiRoutes.customer.tasks.getSingleCard(cardId))

	setListOrder = (boardId: string, listId: string, orderOfCards: string[]): Observable<{ cardId: string }> =>
		this.http.put<{ cardId: string }>(ApiRoutes.customer.tasks.setListOrder(boardId, listId), { orderOfCards })

	setColumnsOrder = (boardId: string, orderOfLists: string[]): Observable<{ cardId: string }> =>
		this.http.put<{ cardId: string }>(ApiRoutes.customer.tasks.setColumnsOrder(boardId), { orderOfLists })

	assignCardToList = (cardId: string, fromListId: string, toListId: string, orderOfCards: string[]): Observable<{}> =>
		this.http.put<{}>(ApiRoutes.customer.tasks.assignCardToList(cardId), { from: fromListId, to: toListId, orderOfCards })

	assignEmployeeToCard = (cardId: string, employeeId: string): Observable<any> =>
		this.http.put<any>(ApiRoutes.customer.tasks.toggleEmployeeToCardAssignment(cardId), { workerId: employeeId })

	unassignEmployeeFromCard = (cardId: string, employeeId: string): Observable<any> =>
		this.http.delete<any>(ApiRoutes.customer.tasks.toggleEmployeeToCardAssignment(cardId), { body: { workerId: employeeId } })

	assignDriverToCard = (cardId: string, employeeId: string): Observable<any> =>
		this.http.put<any>(ApiRoutes.customer.tasks.toggleDriverToCardAssignment(cardId), { driverId: employeeId })

	unassignDriverFromCard = (cardId: string, employeeId: string): Observable<any> =>
		this.http.delete<any>(ApiRoutes.customer.tasks.toggleDriverToCardAssignment(cardId), { body: { driverId: employeeId } })

	changeTaskStatus = (cardId: string, taskId: string, status: Status): Observable<any> =>
		this.http.put<any>(ApiRoutes.customer.tasks.changeTaskStatus(cardId, taskId), { status })

	addCardComment = (cardId: string, content: string): Observable<Comment> =>
		this.http.post<Comment>(ApiRoutes.customer.tasks.addComment(cardId), { content });

	addAttachment = (cardId: string, fileName: string, file: string): Observable<Attachment> =>
		this.http.post<Attachment>(ApiRoutes.customer.tasks.addAttachment(cardId), { attachment_name: fileName, attachment: file })

	deleteAttachment = (cardId: string, attachmentId: string): Observable<any> =>
		this.http.delete<any>(ApiRoutes.customer.tasks.deleteAttachment(cardId, attachmentId))

	optimize = (payload: Optimization): Observable<OptimizationResult> =>
		this.http.post<OptimizationResult>(ApiRoutes.customer.tasks.optimize, payload)

	acceptOptimization = (jobId: string, boardId: string): Observable<any> =>
		this.http.put<any>(ApiRoutes.customer.tasks.acceptOptimization, { jobId, boardId });

	updateDriverDefaultAddress = (payload: DriverDefaultAddressUpdate): Observable<any> =>
		this.http.put<any>(ApiRoutes.customer.tasks.updateDrvierDefaultAddress, payload);

	getDriverTasksSort = (boardId: string, driverId: string): Observable<DriverVirtualTaskSort> =>
		this.http.get<DriverVirtualTaskSort>(ApiRoutes.customer.tasks.getDriverTasksSort(boardId, driverId));

	filterCardsByDate = (cards: Card[], start: Date | null, end: Date | null): Card[] => {
		if (!start || !end) {
			return cards;
		}

		const stripTime = (date: Date): number => {
			return new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime();
		};

		const isDateInRange = (date: Date): boolean => {
			const d = stripTime(date);
			const s = stripTime(start);
			const e = stripTime(end);
			return d >= s && d <= e;
		};

		return cards.filter(card => {
			if (card.type === CardType.simpleCard) {
				const fromDate = new Date(card.from);
				const toDate = new Date(card.to);
				return isDateInRange(fromDate) || isDateInRange(toDate);
			}
			if (card.type === CardType.visit) {
				return card.availableTimeslots.some(slot => {
					const date = new Date(slot.date);
					const start = new Date(`${slot.date}T${slot.startTime}`);
					const end = new Date(`${slot.date}T${slot.endTime}`);
					return isDateInRange(date) || isDateInRange(start) || isDateInRange(end);
				});
			}
			if (card.type === CardType.simpleTransport) {
				return card.transportTasks.some(task => {
					const taskDate = new Date(task.deadline);
					return isDateInRange(taskDate);
				});
			}
			if (card.type === CardType.shipments) {
				return card.shipmentsTransportTasks.some(task => {
					const taskStart = new Date(task.timeslot.start);
					const taskEnd = new Date(task.timeslot.end);
					return isDateInRange(taskStart) || isDateInRange(taskEnd);
				});
			}
			return false;
		});
	}
}