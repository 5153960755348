import { ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CardType } from '../../enums/card-type.enum';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BoardService } from '../../services/board.service';
import { Address, SimpleCard, SimpleTransportCard, TransportTask } from '../../interfaces/card.interface';
import { Observable, Subscription, combineLatest, concat, debounceTime, distinctUntilChanged, fromEvent, map, switchMap, take, toArray, } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from 'src/app/state/app.state';
import { addAttachment, addComment, deleteAttachment, setSingleCard, setSingleListCards } from 'src/app/state/board/board.actions';
import { Card } from '../../interfaces/card.interface';
import { Employee } from 'src/app/customer/employees/interfaces/employee.interface';
import { selectDrivers, selectEmployees } from 'src/app/state/employees/employees.selectors';
import { Driver } from 'src/app/customer/map/interfaces/driver.interface';
import { EmployeeState } from 'src/app/customer/employees/enums/employee-state.enum';
import { TaskStatus } from '../../enums/task-status.enum';
import { LatLng } from 'src/app/shared/models/lat-lng.model';
import { DateAdapter } from '@angular/material/core';
import { TranslocoService } from '@ngneat/transloco';
import { selectUserProfile } from 'src/app/state/auth/auth.selectors';
import { UserProfile } from 'src/app/shared/interfaces/user-profile.interface';
import { selectShipments } from 'src/app/state/shipments/shipments.selectors';
import { Shipment } from 'src/app/customer/shared/interfaces/shipment.interface';
import { selectContractors } from 'src/app/state/contractors/contractors.selectors';
import { Dimensions } from 'src/app/customer/fleet/interfaces/vehicle.interface';

@Component({
	selector: 'app-add-new-card-modal',
	templateUrl: './add-new-card-modal.component.html',
	styleUrls: ['./add-new-card-modal.component.scss']
})
export class AddNewCardModalComponent implements OnInit, OnDestroy {
	@ViewChild('pickupAutocompleteInput') pickupAutocompleteInput!: ElementRef;
	@ViewChild('dropoffAutocompleteInput') dropoffAutocompleteInput!: ElementRef;
	@ViewChild('shipmentAutocompleteInput') shipmentAutocompleteInput!: ElementRef;
	@ViewChild('fileInput') fileInput: ElementRef;

	_CardType = CardType;

	subscription: Subscription = new Subscription();
	cardTypes: CardType[] = [CardType.simpleCard, CardType.simpleTransport, CardType.shipments];
	formGroup: FormGroup
	cardTypeFormGroup: FormGroup;
	selectedCardType: CardType;
	saveProcessing: boolean = false;
	mode: 'new' | 'preview' | 'edit' = 'new';

	pickupPredictions: google.maps.places.AutocompletePrediction[] = []
	dropoffPredictions: google.maps.places.AutocompletePrediction[] = []
	selectedPickupPrediciton: google.maps.places.AutocompletePrediction;
	selectedDropoffPrediciton: google.maps.places.AutocompletePrediction;

	assignedUsers: (Employee | Driver)[] = [];
	users: (Employee | Driver)[] = [];

	newComment: string;
	addingComment: boolean = false;

	newCardId: string;
	attachmentsForNewCard: Array<{ attachmentId: string, name: string; url: string, file: string }> = [];

	minPickupStartTime: string = '00:00';
	maxPickupEndTime: string = '23:30';
	minPickupEndTime: string = '00:00';

	minDropoffStartTime: string = '00:00';
	maxDropoffEndTime: string = '23:30';
	minDropoffEndTime: string = '00:00';

	filteredShipments: Array<Shipment & { contractorName: string; contractorSymbol: string }> = [];
	selectedShipments: Shipment[] = [];

	private currentUser: UserProfile | null;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { boardId: string, listId: string, card?: Card | undefined, archived: boolean | undefined },
		public dialogRef: MatDialogRef<AddNewCardModalComponent>,
		private fb: FormBuilder,
		private boardService: BoardService,
		private store: Store<State>,
		private cdr: ChangeDetectorRef,
		private dateAdapter: DateAdapter<Date>,
		private translocoService: TranslocoService,
	) { }

	get minPickupStartDate(): Date {
		return new Date();
	}

	get minPickupEndDate(): Date {
		const startDate = this.cardTypeFormGroup.get('pickupStartDate')?.value;
		return startDate ? new Date(startDate) : new Date();
	}

	get minDropoffStartDate(): Date {
		const pickupEndDate = this.cardTypeFormGroup.get('pickupEndDate')?.value;
		return pickupEndDate ? new Date(pickupEndDate) : new Date();
	}

	get minDropoffEndDate(): Date {
		const startDate = this.cardTypeFormGroup.get('dropoffStartDate')?.value;
		return startDate ? new Date(startDate) : new Date();
	}

	get shipments(): FormArray {
		return this.cardTypeFormGroup.get('shipments') as FormArray;
	}

	ngOnInit(): void {
		this.initFormGroup();
		this.getCurrentUser();
		this.getActiveEmployeesAndDrivers();
		this.setPreviewMode()
		this.dateAdapter.setLocale(this.translocoService.getActiveLang())
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	setPreviewMode = (): void => {
		if (this.data?.card) {
			this.mode = 'preview';
			this.initCardTypeFormGroup(this.data.card.type);
			this.fillCard();
			this.sortCommentsByDate();
		}
	}

	setEditMode = (): void => {
		this.mode = 'edit';
		this.formGroup.enable();

		if (this.data.card?.type === CardType.simpleTransport) {
			this.formGroup.controls['type'].disable();
			this.cardTypeFormGroup.enable();
			this.initAutocompletes();
		} else if (this.data.card?.type === CardType.simpleCard) {
			this.cardTypeFormGroup.enable();
		}
	}

	saveCard = async (): Promise<void> => {
		if (this.canSave()) {
			this.saveProcessing = true;
			const card = await this.createCard();

			if (this.mode === 'edit') {
				this.subscription.add(
					this.boardService.editCard(this.data.card!.cardId!, card).pipe(
						switchMap(() => this.boardService.getBoardListCards(this.data.boardId, this.data.listId))
					).subscribe({
						next: (value) => {
							this.store.dispatch(setSingleListCards({ listId: this.data.listId, cards: value }))
							this.dialogRef.close();
						},
					})
				)
			} else {
				this.subscription.add(
					this.boardService.createCard(this.data.boardId, this.data.listId, card).pipe(
						switchMap((createdCard) => {
							this.newCardId = createdCard.cardId;

							const attachmentObservables = this.attachmentsForNewCard.map(attachment =>
								this.boardService.addAttachment(this.newCardId, attachment.name, attachment.file)
							);

							return concat(...attachmentObservables).pipe(
								toArray(),
								switchMap(() => this.boardService.getBoardListCards(this.data.boardId, this.data.listId))
							);
						}),
					).subscribe({
						next: (value) => {
							this.store.dispatch(setSingleListCards({ listId: this.data.listId, cards: value }))
							this.dialogRef.close();
						},
					})
				)
			}
		}
	}

	handleAssignedUserChange = (e: { assignedUsers: (Employee | Driver)[]; currentChange: Employee | Driver }): void => {
		const assignedIdx = this.assignedUsers.findIndex((assignedUser) => e.currentChange === assignedUser)
		this.toggleBackendUserAssignment(assignedIdx >= 0 ? 'assign' : 'unassign', e.currentChange);
		this.assignedUsers = e.assignedUsers;
		this.updateCardAssignment();
	}

	selectPrediction = async (prediction: google.maps.places.AutocompletePrediction, type: 'pickup' | 'dropoff'): Promise<void> => {
		if (type === 'pickup') {
			this.selectedPickupPrediciton = prediction;
			this.cardTypeFormGroup.patchValue({
				pickupAddress: [prediction.description]
			})
			if (this.mode === 'edit' && this.isSimpleTransportCard(this.data.card!)) {
				this.data.card!.transportTasks[0].address = await this.getPlaceAddress(prediction);
			}
			this.pickupPredictions = [];
		} else {
			this.selectedDropoffPrediciton = prediction;
			this.cardTypeFormGroup.patchValue({
				dropoffAddress: [prediction.description]
			})
			if (this.mode === 'edit' && this.isSimpleTransportCard(this.data.card!)) {
				this.data.card!.transportTasks[1].address = await this.getPlaceAddress(prediction);
			}
			this.dropoffPredictions = [];
		}
	}

	initCardTypeFormGroup = (cardType: CardType): void => {
		this.selectedCardType = cardType;
		if (cardType === CardType.simpleTransport) {
			this.cardTypeFormGroup = this.fb.group({
				pickupAddress: ['', Validators.required],
				pickupDate: ['', Validators.required],
				pickupTime: ['', Validators.required],
				pickupNote: [''],
				dropoffAddress: ['', Validators.required],
				dropoffDate: ['', Validators.required],
				dropoffTime: ['', Validators.required],
				dropoffNote: ['']
			})

			if (this.mode !== 'preview') {
				this.initAutocompletes();
			}
		} else if (cardType === CardType.simpleCard) {
			this.cardTypeFormGroup = this.fb.group({
				from: ['', Validators.required],
				to: ['', Validators.required],
			})
		} else if (cardType === CardType.shipments) {
			this.cardTypeFormGroup = this.fb.group({
				pickupAddress: ['', Validators.required],
				pickupStartDate: ['', Validators.required],
				pickupStartTime: ['', Validators.required],
				pickupEndDate: ['', Validators.required],
				pickupEndTime: ['', Validators.required],
				pickupNote: [''],
				dropoffAddress: ['', Validators.required],
				dropoffStartDate: ['', Validators.required],
				dropoffStartTime: ['', Validators.required],
				dropoffEndDate: ['', Validators.required],
				dropoffEndTime: ['', Validators.required],
				dropoffNote: [''],
				shipments: this.fb.array([])
			})

			if (this.mode !== 'preview') {
				this.initAutocompletes();
				this.initShipmentsAutocomplete();
				this.setupFormTimeslotsListeners();
			}
		} else {
			this.cardTypeFormGroup = this.fb.group({})
		}
	}

	canSave = (): boolean => {
		if (this.cardTypeFormGroup) {
			return this.formGroup.valid && this.cardTypeFormGroup.valid && !this.saveProcessing;
		}
		return this.formGroup.valid && !this.saveProcessing;
	}

	addNewComment = (): void => {
		if (!this.addingComment) {
			this.addingComment = true;
			this.subscription.add(
				this.boardService.addCardComment(this.data.card!.cardId, this.newComment).subscribe({
					next: (comment) => {
						this.store.dispatch(addComment({
							cardId: this.data.card?.cardId ?? '',
							listId: this.data.listId,
							comment: comment
						}))
						this.data.card?.comments?.push(comment);
						this.newComment = '';
						this.sortCommentsByDate();
						this.addingComment = false;
						this.cdr.detectChanges();
					},
					error: (err) => {
						this.addingComment = false;
					},
				})
			)
		}
	}

	getUserAvatar = (id: string): string => {
		const foundUser = this.users.find((user) => user.driverId === id || user?.id === id);
		const foundCurrentUser = this.currentUser?.id === id ? this.currentUser : undefined;

		if (foundUser) {
			return foundUser?.avatar ?? ''
		}
		if (foundCurrentUser) {
			return foundCurrentUser?.avatar ?? ''
		}
		return ''
	}

	getUserName = (id: string): string => {
		const foundUser = this.users.find((user) => user.driverId === id || user?.id === id);
		const foundCurrentUser = this.currentUser?.id === id ? this.currentUser : undefined;

		if (foundUser) {
			return foundUser?.name ?? ''
		}
		if (foundCurrentUser) {
			return foundCurrentUser?.name ?? ''
		}
		return ''
	}

	addAtachment = (fileName: string, file: string): void => {
		this.subscription.add(
			this.boardService.addAttachment(this.data.card?.cardId ?? '', fileName, file).subscribe({
				next: (attachment) => {
					this.store.dispatch(addAttachment({
						cardId: this.data.card?.cardId ?? '',
						listId: this.data.listId,
						attachment: attachment
					})),
						this.data.card?.attachments?.push(attachment);
				},
			})
		)
	}

	openAttachment = (url: string): void => {
		window.open(url, '_blank');
	}

	deleteAttachment = (id: string): void => {
		if (this.mode === 'preview' || this.mode === 'edit') {
			this.subscription.add(
				this.boardService.deleteAttachment(this.data.card?.cardId ?? '', id).subscribe({
					next: () => {
						this.store.dispatch(deleteAttachment({
							cardId: this.data.card!.cardId ?? '',
							listId: this.data.listId,
							attachmentId: id
						}))
						this.data.card!.attachments = this.data.card?.attachments?.filter((attachment) => attachment.attachmentId !== id)
					},
				})
			)
		} else {
			this.attachmentsForNewCard = this.attachmentsForNewCard.filter(attachment => attachment.attachmentId !== id);
		}
	}

	openFilePicker() {
		this.fileInput.nativeElement.click();
	}

	onFileSelected = (event: any) => {
		const file = event.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = (event: ProgressEvent<FileReader>) => {
				const base64String = event.target?.result as string;

				if (this.mode === 'preview' || this.mode === 'edit') {
					this.addAtachment(file.name, base64String);
				} else {
					const temporaryId = 'id-' + Date.now() + '-' + Math.floor(Math.random() * 1000);
					this.attachmentsForNewCard.push({
						attachmentId: temporaryId,
						name: file.name,
						url: this.createUrlFromBase64String(base64String, file.type),
						file: base64String
					});
				}
			};
			reader.readAsDataURL(file);
		}
	}

	onShipmentInput(event: Event): void {
		const query = (event.target as HTMLInputElement).value;
		this.filterShipments(query);
	}
	
	selectShipment(shipment: Shipment): void {
		if (!this.selectedShipments.some(s => s.id === shipment.id)) {
			this.selectedShipments.push(shipment);
		}
		this.filteredShipments = [];
		this.shipmentAutocompleteInput.nativeElement.value = '';
	}
	
	removeShipment(shipment: Shipment): void {
		this.selectedShipments = this.selectedShipments.filter(s => s.id !== shipment.id);
	}

	formatDate(date: string | null | undefined): string {
		if (!date) return 'Brak daty';
		const d = new Date(date);
		return `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth() + 1).toString().padStart(2, '0')}/${d.getFullYear()}`;
	}
	
	formatDimensions(dimensions: Dimensions | null | undefined): string {
		if (!dimensions) return '-';
		return `${dimensions?.length?.value ?? '-'}${dimensions?.length?.unit ?? ''} x 
			${dimensions?.width?.value ?? '-'}${dimensions?.width?.unit ?? ''} x 
			${dimensions?.height?.value ?? '-'}${dimensions?.height?.unit ?? ''}`;
	}

	private setupFormTimeslotsListeners(): void {
		this.cardTypeFormGroup.get('pickupStartDate')?.valueChanges.subscribe(() => {
			this.updateTimeRestrictions();
			this.validateEndDate();
		});

		this.cardTypeFormGroup.get('pickupStartTime')?.valueChanges.subscribe(() => {
			this.updateTimeRestrictions();
		});

		this.cardTypeFormGroup.get('pickupEndDate')?.valueChanges.subscribe(() => {
			this.updateTimeRestrictions();
		});

		this.cardTypeFormGroup.get('dropoffStartDate')?.valueChanges.subscribe(() => {
			this.updateTimeRestrictions();
			this.validateEndDate();
		});

		this.cardTypeFormGroup.get('dropoffStartTime')?.valueChanges.subscribe(() => {
			this.updateTimeRestrictions();
		});

		this.cardTypeFormGroup.get('dropoffEndDate')?.valueChanges.subscribe(() => {
			this.updateTimeRestrictions();
		});
	}

	private updateTimeRestrictions(): void {
		const now = new Date();
		const currentHour = now.getHours();
		const currentMinute = now.getMinutes();

		const formGroup = this.cardTypeFormGroup;

		const pickupStartDate = formGroup.get('pickupStartDate')?.value;
		const pickupStartTime = formGroup.get('pickupStartTime')?.value;
		const pickupEndDate = formGroup.get('pickupEndDate')?.value;

		const dropoffStartDate = formGroup.get('dropoffStartDate')?.value;
		const dropoffStartTime = formGroup.get('dropoffStartTime')?.value;
		const dropoffEndDate = formGroup.get('dropoffEndDate')?.value;

		this.minPickupStartTime = '00:00';
		this.maxPickupEndTime = '23:30';
		this.minDropoffStartTime = '00:00';
		this.maxDropoffEndTime = '23:30';

		if (pickupStartDate) {
			const startDateObj = new Date(pickupStartDate);
			if (startDateObj.toDateString() === now.toDateString()) {
				this.minPickupStartTime = `${this.pad(currentHour)}:${this.pad(currentMinute)}`;
			}
		}

		if (pickupStartDate && pickupEndDate) {
			const startDateObj = new Date(pickupStartDate);
			const endDateObj = new Date(pickupEndDate);

			if (startDateObj.getTime() === endDateObj.getTime() && pickupStartTime) {
				this.minPickupEndTime = this.addMinutesToTime(pickupStartTime, 1);
			}
		}

		if (dropoffStartDate) {
			const startDateObj = new Date(dropoffStartDate);
			if (startDateObj.toDateString() === now.toDateString()) {
				this.minDropoffStartTime = `${this.pad(currentHour)}:${this.pad(currentMinute)}`;
			}
		}

		if (dropoffStartDate && dropoffEndDate) {
			const startDateObj = new Date(dropoffStartDate);
			const endDateObj = new Date(dropoffEndDate);

			if (startDateObj.getTime() === endDateObj.getTime() && dropoffStartTime) {
				this.minDropoffEndTime = this.addMinutesToTime(dropoffStartTime, 1);
			}
		}
	}

	private validateEndDate(): void {
		const pickupStartDate = this.cardTypeFormGroup.get('pickupStartDate')?.value;
		const pickupEndDate = this.cardTypeFormGroup.get('pickupEndDate')?.value;
		const dropoffStartDate = this.cardTypeFormGroup.get('dropoffStartDate')?.value;
		const dropoffEndDate = this.cardTypeFormGroup.get('dropoffEndDate')?.value;

		if (pickupStartDate && pickupEndDate) {
			const pickupStartObj = new Date(pickupStartDate);
			const pickupEndObj = new Date(pickupEndDate);

			if (pickupStartObj > pickupEndObj) {
				this.cardTypeFormGroup.get('pickupEndDate')?.setValue(null);
			}
		}

		if (dropoffStartDate && dropoffEndDate) {
			const dropoffStartObj = new Date(dropoffStartDate);
			const dropoffEndObj = new Date(dropoffEndDate);

			if (dropoffStartObj > dropoffEndObj) {
				this.cardTypeFormGroup.get('dropoffEndDate')?.setValue(null);
			}
		}
	}

	private pad(value: number): string {
		return value < 10 ? `0${value}` : `${value}`;
	}

	private addMinutesToTime(time: string, minutes: number): string {
		const [hour, minute] = time.split(':').map(Number);
		const date = new Date();
		date.setHours(hour, minute + minutes);
		return `${this.pad(date.getHours())}:${this.pad(date.getMinutes())}`;
	}

	private createUrlFromBase64String = (base64: string, mimeType: string): string => {
		const base64Prefix = 'base64,';
		const base64Index = base64.indexOf(base64Prefix);
		if (base64Index !== -1) {
			base64 = base64.substring(base64Index + base64Prefix.length);
		}

		const byteCharacters = atob(base64);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		const blob = new Blob([byteArray], { type: mimeType });
		return URL.createObjectURL(blob);
	}

	private sortCommentsByDate = (): void => {
		if (this.data && this.data.card && this.data.card.comments) {
			this.data.card.comments.sort((a, b) => {
				return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
			});
		}
	}

	private initAutocompletes = (): void => {
		setTimeout(() => {
			this.initAutocomplete(this.pickupAutocompleteInput, 'pickup');
			this.initAutocomplete(this.dropoffAutocompleteInput, 'dropoff');
		}, 100);
	}

	private initShipmentsAutocomplete(): void {
		setTimeout(() => {
			const inputEvents: Observable<Event> = fromEvent(this.shipmentAutocompleteInput.nativeElement, 'input');
			inputEvents.pipe(
				map((event: Event) => (event.target as HTMLInputElement).value),
				debounceTime(300),
				distinctUntilChanged()
			).subscribe(query => {
				if (query) {
					this.filterShipments(query);
				} else {
					this.filteredShipments = [];
				}
			});
		}, 100);
	}

	filterShipments(query: string): void {
		query = query.toLowerCase();

		combineLatest([
			this.store.select(selectShipments),
			this.store.select(selectContractors),
		]).pipe(
			take(1)
		).subscribe(([shipments, contractors]) => {
			this.filteredShipments = shipments
				.map(shipment => {
					const contractor = contractors.find(c => c.id === shipment.contractorId);
					return {
						...shipment,
						contractorName: contractor ? contractor.name.toLowerCase() : '',
						contractorSymbol: contractor?.symbol ? contractor.symbol.toLowerCase() : ''
					};
				})
				.filter(shipment =>
					shipment.contractorName.includes(query) ||
					shipment.contractorSymbol.includes(query) ||
					shipment.details.number?.toLowerCase().includes(query) ||
					shipment.details.notes?.toLowerCase().includes(query) ||
					shipment.details.tags?.some(tag => tag.toLowerCase().includes(query))
				).filter((shipment) => !this.selectedShipments.find((s) => s.id === shipment.id));
		});
	}

	private updateCardAssignment = (): void => {
		if (this.data.card?.cardId) {
			const card = Object.assign({}, this.data.card)
			card.assignedWorkers = this.assignedUsers.filter((user) => user?.id).map((user) => (user.id!));
			card.assignedDriver = this.assignedUsers.find((user) => user?.driverId)?.driverId ?? null
			this.store.dispatch(setSingleCard({ listId: this.data.listId, card: card }))
		}
	}

	private toggleBackendUserAssignment = (action: 'assign' | 'unassign', user: Employee | Driver): void => {
		if (action === 'assign') {
			if (this.data.card?.cardId) {
				if (user?.id) {
					this.assignEmployeeToExistingCard(this.data.card!.cardId, user.id)
				} else if (user?.driverId) {
					this.assignDriverToExistingCard(this.data.card!.cardId, user.driverId)
				}
			}
		}
		if (action === 'unassign') {
			if (this.data.card?.cardId) {
				if (user?.id) {
					this.unassignEmployeeToExistingCard(this.data.card!.cardId, user.id)
				} else if (user?.driverId) {
					this.unassignDriverToExistingCard(this.data.card!.cardId, user.driverId)
				}
			}
		}
	}

	private assignEmployeeToExistingCard = (cardId: string, employeeId: string): void => {
		this.subscription.add(
			this.boardService.assignEmployeeToCard(cardId, employeeId).subscribe()
		)
	}

	private assignDriverToExistingCard = (cardId: string, employeeId: string): void => {
		this.subscription.add(
			this.boardService.assignDriverToCard(cardId, employeeId).subscribe()
		)
	}

	private unassignEmployeeToExistingCard = (cardId: string, employeeId: string): void => {
		this.subscription.add(
			this.boardService.unassignEmployeeFromCard(cardId, employeeId).subscribe()
		)
	}

	private unassignDriverToExistingCard = (cardId: string, employeeId: string): void => {
		this.subscription.add(
			this.boardService.unassignDriverFromCard(cardId, employeeId).subscribe()
		)
	}

	private getActiveEmployeesAndDrivers = (): void => {
		this.subscription.add(
			combineLatest([
				this.store.select((state) => selectEmployees(state)),
				this.store.select((state) => selectDrivers(state))
			]).pipe(
				map(([employees, drivers]) => {
					return [
						...employees.filter((employee) => employee.state === EmployeeState.active),
						...drivers.filter((driver) => driver.state === EmployeeState.active)
					];
				})
			).subscribe(combinedList => {
				this.users = combinedList;
			})
		);
	}

	private getCurrentUser = (): void => {
		this.subscription.add(
			this.store.select((state) => selectUserProfile(state)).subscribe((user) => this.currentUser = user)
		)
	}

	private fillCard = (): void => {
		const { name, description, type, assignedWorkers, assignedDriver } = this.data.card!;

		this.setAssignedUsers([...assignedWorkers, assignedDriver ?? '']);

		this.formGroup.patchValue({
			name: name,
			description: description,
			type: type,
		})
		this.formGroup.disable();

		if (type === CardType.simpleTransport && this.isSimpleTransportCard(this.data.card!)) {
			const { transportTasks } = this.data.card!;
			this.cardTypeFormGroup.patchValue({
				pickupAddress: transportTasks[0]?.address?.freeformAddress ?? '',
				pickupDate: transportTasks[0]?.deadline,
				pickupTime: this.formatTime(new Date(transportTasks[0]?.deadline)),
				pickupNote: transportTasks[0]?.note,
				dropoffAddress: transportTasks[1]?.address?.freeformAddress ?? '',
				dropoffDate: transportTasks[1]?.deadline,
				dropoffTime: this.formatTime(new Date(transportTasks[1]?.deadline)),
				dropoffNote: transportTasks[1]?.note
			})
		}
		if (type === CardType.simpleCard && this.isSimpleCard(this.data.card!)) {
			this.cardTypeFormGroup.patchValue({
				from: this.data.card.from,
				to: this.data.card.to
			})
		}
		this.cardTypeFormGroup.disable();
	}

	private setAssignedUsers = (assignedUsersIds: string[]): void => {
		assignedUsersIds.forEach((assignedId) => {
			const user = this.users.find(
				(user) => user?.id === assignedId || user?.driverId === assignedId
			);
			if (user) {
				this.assignedUsers.push(user);
			}
		});
	}

	private createCard = async (): Promise<SimpleTransportCard | SimpleCard> => {
		if (this.formGroup.controls['type'].value === CardType.simpleTransport) {
			return this.createSimpleTransportCard();
		} else {
			return this.createSimpleCard()
		}
	}

	private createSimpleCard = async (): Promise<SimpleCard> => {
		return Promise.resolve({
			cardId: this.data?.card?.cardId ?? '',
			type: CardType.simpleCard,
			name: this.formGroup.value.name,
			description: this.formGroup.value.description,
			from: new Date(this.cardTypeFormGroup.controls['from'].value).toISOString(),
			to: new Date(this.cardTypeFormGroup.controls['to'].value).toISOString(),
			assignedWorkers: this.assignedUsers.filter((user) => user?.id).map((user) => (user.id!)),
			assignedDriver: this.assignedUsers.find((user) => user?.driverId)?.driverId ?? null,
			comments: this.data?.card?.comments ?? [],
			attachments: this.data?.card?.attachments ?? [],
			archived: this.data?.card?.archived ?? false
		});
	}

	private createSimpleTransportCard = async (): Promise<SimpleTransportCard> => {
		let transportTasks: TransportTask[] = [];

		const currCard = this.data.card as SimpleTransportCard | undefined;

		transportTasks = [
			{
				taskId: currCard?.transportTasks[0]?.taskId ?? '',
				type: 'pickup',
				address: currCard?.transportTasks[0]?.address ?? await this.getPlaceAddress(this.selectedPickupPrediciton),
				deadline: this.getDeadline('pickup'),
				note: this.cardTypeFormGroup.value.pickupNote,
				status: currCard?.transportTasks[0]?.status ?? TaskStatus.scheduled
			},
			{
				taskId: currCard?.transportTasks[1]?.taskId ?? '',
				type: 'dropoff',
				address: currCard?.transportTasks[1]?.address ?? await this.getPlaceAddress(this.selectedDropoffPrediciton),
				deadline: this.getDeadline('dropoff'),
				note: this.cardTypeFormGroup.value.dropoffNote,
				status: currCard?.transportTasks[1]?.status ?? TaskStatus.scheduled
			}
		];

		const card: SimpleTransportCard = {
			cardId: this.data?.card?.cardId ?? '',
			name: this.formGroup.value.name,
			type: CardType.simpleTransport,
			description: this.formGroup.value.description,
			assignedWorkers: this.assignedUsers.filter((user) => user?.id).map((user) => user.id!),
			assignedDriver: this.assignedUsers.find((user) => user?.driverId)?.driverId ?? null,
			transportTasks: transportTasks,
			comments: this.data?.card?.comments ?? [],
			attachments: this.data?.card?.attachments ?? [],
			archived: this.data?.card?.archived ?? false,
		};
		return card;
	}

	private formatTime(deadline: Date): string {
		const date = new Date(deadline);
		const hours = date.getHours().toString().padStart(2, '0');
		const minutes = date.getMinutes().toString().padStart(2, '0');
		return `${hours}:${minutes}`;
	}

	private getDeadline = (type: 'pickup' | 'dropoff'): string => {
		const date = new Date(this.cardTypeFormGroup.value[`${type}Date`]);
		const time = this.cardTypeFormGroup.value[`${type}Time`] as string;
		const [hours, minutes] = time.split(':').map(Number);
		date.setHours(hours);
		date.setMinutes(minutes);
		date.setSeconds(0);
		date.setMilliseconds(0);
		return date.toISOString();
	}

	private getPlaceAddress = (prediction: google.maps.places.AutocompletePrediction): Promise<Address> => {
		return new Promise((resolve, reject) => {
			const address: Address = {
				streetName: '',
				streetNumber: '',
				flatNumber: '',
				postalCode: '',
				city: '',
				country: '',
				freeformAddress: prediction.description,
				coords: new LatLng(1, 1)
			};
			const placesService = new google.maps.places.PlacesService(document.createElement('div'));
			placesService.getDetails({ placeId: prediction.place_id }, (place, status) => {
				if (status === google.maps.places.PlacesServiceStatus.OK && place) {
					address.coords = new LatLng(place.geometry?.location.lat() ?? 0, place.geometry?.location.lng() ?? 0)
					place.address_components?.forEach(component => {
						const addressType = component.types[0];
						switch (addressType) {
							case 'street_number':
								address.streetNumber = component.long_name;
								break;
							case 'route':
								address.streetName = component.long_name;
								break;
							case 'locality':
								address.city = component.long_name;
								break;
							case 'postal_code':
								address.postalCode = component.long_name;
								break;
							case 'country':
								address.country = component.long_name;
								break;
						}
					});
					resolve(address);
				} else {
					reject(`Failed to get details for placeId: ${prediction.place_id}`);
				}
			});
		});
	}

	private search(query: string, type: 'pickup' | 'dropoff'): void {
		const service = new google.maps.places.AutocompleteService();
		if (query.length > 0) {
			service.getPlacePredictions({ input: query }, (predictions, status) => {
				if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
					if (type === 'pickup') {
						this.pickupPredictions = predictions;
					} else {
						this.dropoffPredictions = predictions;
					}
				}
			});
		}
	}

	private initAutocomplete(autocompleteInput: ElementRef, type: 'pickup' | 'dropoff'): void {
		const inputEvents: Observable<Event> = fromEvent(autocompleteInput.nativeElement, 'input');
		inputEvents.pipe(
			map((event: Event) => (event.target as HTMLInputElement).value),
			debounceTime(500),
			distinctUntilChanged()
		).subscribe(query => {
			if (query) {
				this.search(query, type);
			} else {
				if (type === 'pickup') {
					this.pickupPredictions = [];
				} else {
					this.dropoffPredictions = [];
				}
			}
		});
	}

	private initFormGroup = (): void => {
		this.formGroup = this.fb.group({
			type: ['', Validators.required],
			name: ['', [Validators.required, Validators.maxLength(40)]],
			description: ['', Validators.required],
		})
	}

	private isSimpleTransportCard = (card: Card): card is SimpleTransportCard => {
		return card.type === CardType.simpleTransport;
	}

	private isSimpleCard = (card: Card): card is SimpleCard => {
		return card.type === CardType.simpleCard;
	}
}
