import { Injectable } from '@angular/core';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { Observable, from, startWith, take, tap, withLatestFrom } from 'rxjs';
import { LiveDataEvent } from '../map/interfaces/live-data-event.interface';
import { LiveDataEventType } from '../map/enums/live-data-event-type.enum';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Store, select } from '@ngrx/store';
import { State } from 'src/app/state/app.state';
import { setDriver, updateDriverSpeed } from 'src/app/state/employees/employees.actions';
import { updateDriverCoords } from 'src/app/state/map/map.actions';
import { selectDrivers } from 'src/app/state/employees/employees.selectors';
import { addNotification } from 'src/app/state/notifications/notifications.actions';
import { Notification } from './interfaces/notification.interface';
import { NotificationType } from './enums/notification-type.enum';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import * as moment from 'moment';
import { MappedAddress } from '../contractors/types.interface';
@Injectable({
	providedIn: 'root'
})
export class SharedService {
	private notificationTypes = [
		LiveDataEventType.userUpdatedCard,
		LiveDataEventType.userUnassignedToCard,
		LiveDataEventType.userAssignedToCard,
		LiveDataEventType.userDeletedCard,
		LiveDataEventType.userArchivedCard
	];

	constructor(
		private http: HttpClient,
		private store: Store<State>,
	) {}

	createLiveDataEventSource(publicUrl: string, id: string, jwt: string, channelFor: 'organization' | 'user'): Observable<any> {
		const url = `${publicUrl}?topic=${publicUrl}/${channelFor}/${id}`;
		return new Observable(observer => {
			from(
				fetchEventSource(url, {
					headers: {
						'Authorization': `Bearer ${jwt}`
					},
					onmessage: (event) => {
						const messageData: LiveDataEvent = JSON.parse(event.data);
						this.handleLiveDataEvent(messageData)
						observer.next(messageData)
					},
					onerror(err) {
						console.error(err)
					},
					keepalive: true,
				})
			)
		}).pipe(
			tap(),
			startWith(null)
		);
	}

	loadGoogleApi = (): Observable<any> => {
		return this.http.jsonp(
			`https://maps.googleapis.com/maps/api/js?key=${environment.googlePlacesApiKey}&libraries=places`,
			'callback'
		)
	}

	generateVehicleReportPDF = async (): Promise<void> => {
		const pdf = new jspdf('p', 'mm', 'a4');
		const imgWidth = 210;

		const page1Content = document.getElementById('page1-content');
		const page2Content = document.getElementById('page2-content');

		if (page1Content && page2Content) {
			const renderPage1 = html2canvas(page1Content, {
				useCORS: true,
				ignoreElements: (element) => element.classList?.contains('exclude-from-pdf'),
				onclone: (clonedDoc) => {
					const head = clonedDoc.querySelector('head');
					document.querySelectorAll('style, link[rel="stylesheet"]').forEach((style) => {
						head?.appendChild(style.cloneNode(true));
					});
				},
			});

			const renderPage2 = html2canvas(page2Content, {
				useCORS: true,
				ignoreElements: (element) => element.classList?.contains('exclude-from-pdf'),
				onclone: (clonedDoc) => {
					const head = clonedDoc.querySelector('head');
					document.querySelectorAll('style, link[rel="stylesheet"]').forEach((style) => {
						head?.appendChild(style.cloneNode(true));
					});
				},
			});

			await Promise.all([renderPage1, renderPage2]).then(([canvas1, canvas2]) => {
				const imgHeight1 = (canvas1.height * imgWidth) / canvas1.width;
				const imgData1 = canvas1.toDataURL('image/png');

				const imgHeight2 = (canvas2.height * imgWidth) / canvas2.width;
				const imgData2 = canvas2.toDataURL('image/png');

				pdf.addImage(imgData1, 'PNG', 0, 0, imgWidth, imgHeight1);
				pdf.addPage();
				pdf.addImage(imgData2, 'PNG', 0, 0, imgWidth, imgHeight2);
				pdf.save(`${moment().format('YYYY-MM-DDTHH-mm-ss')}.pdf`);
			});
		}
	}

	getPlaceDetails(placeId: string): Observable<google.maps.places.PlaceResult> {
		const placesService = new google.maps.places.PlacesService(document.createElement('div'));
		return new Observable(observer => {
			placesService.getDetails(
				{
					placeId,
					fields: ['address_components', 'geometry', 'formatted_address']
				},
				(result, status) => {
					if (status === google.maps.places.PlacesServiceStatus.OK && result) {
						observer.next(result);
						observer.complete();
					} else {
						observer.error(status);
					}
				}
			);
		});
	}

	mapPlaceResultToAddress(details: google.maps.places.PlaceResult): MappedAddress {
		const addressComponents = details.address_components || [];
		const findComponent = (type: string): string => {
			const comp = addressComponents.find(c => c.types.includes(type));
			return comp ? comp.long_name : '';
		};
	
		const street = findComponent('route') || '-';
		const buildingNumber = findComponent('street_number') || findComponent('premise');
	
		return {
			freeformAddress: details.formatted_address || '',
			street,
			buildingNumber,
			city: findComponent('locality') || findComponent('administrative_area_level_2'),
			postCode: findComponent('postal_code'),
			country: findComponent('country'),
			coords: details.geometry
				? {
					latitude: details.geometry.location.lat(),
					longitude: details.geometry.location.lng()
				}
				: { latitude: 0, longitude: 0 }
		};
	}

	private handleLiveDataEvent = (event: LiveDataEvent): void => {
		if (event.type === LiveDataEventType.updateCoords) {
			this.store.select(state => selectDrivers(state)).pipe(
				take(1)
			).subscribe(drivers => {
				const driver = drivers.find(d => d.driverId === event.driverId);
				if (driver && event?.coords) {
					this.store.dispatch(updateDriverCoords({ driverId: event.driverId, coords: event.coords, driver }));
				}
			});
		}
		if (event.type === LiveDataEventType.usedInvitation) {
			this.store.dispatch(setDriver({ driver: event.profile!, invitationId: event.invitationId! }))
		}
		if (event.type === LiveDataEventType.updateSpeed) {
			const driverId = event.driverId;
			const value = event.speed!.value;
			const unit = event.speed!.unit;
			this.store.dispatch(updateDriverSpeed({ driverId, speedValue: value, speedUnit: unit }))
		}
		if (this.notificationTypes.includes(event.type)) {
			this.store.dispatch(addNotification({ notification: this.mapEventToNotification(event) }))
		}
	}

	private mapEventToNotification = (event: LiveDataEvent): Notification => {
		const notification: Notification = {
			id: event.id ?? '',
			attributes: event?.attributes ?? [],
			createdAt: event?.createdAt ?? '',
			markedAsRead: event?.markedAsRead ?? false,
			type: event.type as unknown as NotificationType,
		}
		return notification
	}
}
