import { createReducer, on } from '@ngrx/store';
import { addNewContractorAddress, setContractorMainAddress, setContractors, toggleActiveContractorAddressStatus, toggleActiveContractorStatus, updateContractorAddress } from './contractors.actions';
import { EnrichedContractor } from 'src/app/customer/contractors/types.interface';

export interface ContractorsState {
	contractors: EnrichedContractor[]
}

export const initialState: ContractorsState = {
	contractors: [],
}

export const contractorsReducer = createReducer<ContractorsState>(
	initialState,
	on(setContractors, (state, { contractors }): any => ({
		...state,
		contractors
	})),
	on(addNewContractorAddress, (state, { contractorId, address }) => ({
		...state,
		contractors: state.contractors.map(contractor =>
			contractor.id === contractorId
				? { ...contractor, addresses: [...(contractor.addresses || []), address] }
				: contractor
		)
	})),
	on(updateContractorAddress, (state, { contractorId, address }) => ({
		...state,
		contractors: state.contractors.map(contractor =>
			contractor.id === contractorId
				? {
					...contractor,
					addresses: contractor.addresses?.map(addr =>
						addr.id === address.id ? address : addr
					) || []
				}
				: contractor
		)
	})),
	on(setContractorMainAddress, (state, { contractorId, addressId }) => ({
		...state,
		contractors: state.contractors.map(contractor =>
			contractor.id === contractorId
				? { ...contractor, mainAddressId: addressId }
				: contractor
		)
	})),
	on(toggleActiveContractorStatus, (state, { contractorId }) => ({
		...state,
		contractors: state.contractors.map(contractor =>
			contractor.id === contractorId
				? { ...contractor, isActive: !contractor.isActive }
				: contractor
		)
	})),
	on(toggleActiveContractorAddressStatus, (state, { contractorId, addressId }) => ({
		...state,
		contractors: state.contractors.map(contractor => {
			if (contractor.id !== contractorId) return contractor;
			return {
				...contractor,
				addresses: contractor.addresses.map(address =>
					address.id === addressId
						? { ...address, isActive: !address.isActive }
						: address
				)
			};
		})
	}))
)